import React, { Component } from "react";
import {
  Fade,
  Table,
  Row,
  Col,
  Input,
  InputGroup,
  Navbar,
  NavbarBrand,
  Container,
  Toast,
  ToastBody,
  UncontrolledAlert,
  ToastHeader,
  Collapse,
} from "reactstrap"; //Button,
import StickyHeader from "react-sticky-header";
//import { StickyContainer, Sticky } from 'react-sticky';
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import db from "./FirestoreConfig";
import "./App.css";
import FormularioAdquirir from "./componentes/FormularioAdquirir";
import FormularioVer from "./componentes/FormularioVer";
import Terminos from "./componentes/Terminos";
import Cookies from "./componentes/Cookies";
import VideoAnuncio from "./anuncios/videoAds";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-floating-action-button";
import Linkify from "react-linkify";
//import AdSense from 'react-adsense';
//import {Link} from 'react-router-dom'
//import PinchToZoom from 'react-pinch-and-zoom';
import firebase from "firebase/app";
import "firebase/auth";
import validator from "validator";
import ReactLoading from "react-loading";
import ScrollProgressRead from "react-scroll-progress-read";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faGooglePlay,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCalendarAlt,
  faUser,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import {
  faDollarSign,
  faExternalLinkAlt,
  faAward,
  faThumbsUp,
  faTrash,
  faGlobe,
  faPhone,
  faRandom,
  faChess,
  faPlane,
  faTv,
  faCouch,
  faTshirt,
  faRobot,
  faMoneyBillWave,
  faTicketAlt,
  faCocktail,
  faHamburger,
  faBasketballBall,
  faExpand,
  faCompress,
  faAt,
  faInfoCircle,
  faPlay,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import InstallApp from "./componentes/InstallApp";

//const db=firebase.firestore();
//const stg=firebase.storage();
const trackingId = "G-N3YRRXC8F0"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const paises = {
  Argentina: "Pesos argentinos",
  Bolivia: "Bolivianos",
  Chile: "Pesos chilenos",
  Colombia: "Pesos colombianos",
  "Costa Rica": "Colones",
  Cuba: "Pesos cubanos",
  Ecuador: "Dólares ",
  "El Salvador": "Dólares",
  Guatemala: "Quetzales",
  Honduras: "Lempiras",
  México: "Pesos mexicanos",
  Nicaragua: "Córdobas",
  Panamá: "Dólares",
  Paraguay: "Guaraníes",
  Perú: "Soles",
  "Puerto Rico": "Dólares",
  "República Dominicana": "Pesos dominicanos",
  Uruguay: "Pesos uruguayos",
  Venezuela: "Bolívares",
};

//ReactGA.pageview("/");

class App extends Component {
  //estado de la aplicacion (datos no remanentes):
  constructor() {
    super();
    this.cargandoRefM = React.createRef();
    this.state = {
      showDetails: true,
      isOpenMenuBoton: false,
      arrayItemsBoletas: [...new Array(100)].map(
        (item, idx) => (idx = "libres." + idx)
      ),
      fadeln: false,
      pasoDescripción:
        "Crea, gestiona, personaliza y comparte tu talonario virtual con RifaYa, descarga la App y no vuelvas a usar talonarios de papel.",
      existeRifa: false,
      adquirirNueva: true,
      codigoInput: "",
      spinnerInput: 0,
      habilitarModo: false,
      estadoRifa: "",
      datosCliente: "",
      correoAnfitrion: "",
      celularAnfitrion: "",
      nombreAnfitrion: "",
      fechaAnfitrion: "",
      fechaAnfitrionLast: "",
      premioAnfitrion: "",
      linkAnfitrion: "",
      valorBoleta: 0,
      currencyAux: "",
      conteosSorteo: 0,
      rangoSorteo: "",
      ganador: "",
      codigoRifa: "",
      tipoRifa: "",
      clienteCelular: "",
      clienteCorreo: "",
      clienteCelularConfirm: "",
      clienteNombre: "",
      numeroBoleta: "",
      arrayBoletas: {},
      message: "",
      clienteDatosRifa: [],
      verBoletasEnable: false,
      clienteDatosNumeros: {},
      fechaActual: firebase.firestore.Timestamp.now().toDate(), //.toString().substring(0, 15)
      fechaComparada: false,
      todoA: "",
      cargandoApp: false,
      imagenURL: "",
      colores: ["", "", "", "", ""],
      redes: [],
      slogan: "",
      subscrito: false,
      anuncios: true,
      imagenAnuncioH: "",
      imagenAnuncioC: "",
      imagenAnuncioV1: "",
      imagenAnuncioV2: "",
      imagenAnuncioG: "",
      imagenAnuncioE: "",
      imagenLogo: process.env.PUBLIC_URL + "/logo2.png",
      linkAnuncioV: "",
      linkAnuncioFH: "",
      linkAnuncioFV: "",
      arrayAnuncios: [],
      arrayLinks: [],
      arrayFicheros: [],
      publicidadActual: "",
      anunciosWeb: false,
      pais: "",
      infoDetalle: "",
      showInfoDetalle: true,
      marcaRifa: "",
      rifaTipoNum: 0,
    };
    this.handleAddDatosAdquirir = this.handleAddDatosAdquirir.bind(this);
    this.handleAddDatosVer = this.handleAddDatosVer.bind(this);
    this.actualizarAnfitrion = this.actualizarAnfitrion.bind(this);
    this.estadoBoleta = this.estadoBoleta.bind(this);
    this.borrarBoleta = this.borrarBoleta.bind(this);
    this.cargarDatos = this.cargarDatos.bind(this);
    this.imagenTipoRifa = this.imagenTipoRifa.bind(this);
    this.buscarDatosRifa = this.buscarDatosRifa.bind(this);
    this.FuningresarDatosAdquirir = this.FuningresarDatosAdquirir.bind(this);
    this.todoAnfi = this.todoAnfi.bind(this);
    this.handleAdquerirBoletas = this.handleAdquerirBoletas.bind(this);
    this.handleVerBoletas = this.handleVerBoletas.bind(this);
    this.asignarAnuncios = this.asignarAnuncios.bind(this);
    this.clickAnuncioV = this.clickAnuncioV.bind(this);
    this.clickAnuncioFV = this.clickAnuncioFV.bind(this);
    this.clickAnuncioFH = this.clickAnuncioFH.bind(this);
    this.funcionImagenCargada = this.funcionImagenCargada.bind(this);
  }
  imagenTipoRifa() {
    switch (this.state.tipoRifa) {
      case "Otro":
        return faAward;
      case "Juguete":
        return faChess;
      case "Electrodomestico":
        return faTv;
      case "Mueble":
        return faCouch;
      case "Ropa":
        return faTshirt;
      case "Tecnología":
        return faRobot;
      case "Dinero":
        return faMoneyBillWave;
      case "Tarjeta de regalo":
        return faTicketAlt;
      case "Viaje":
        return faPlane;
      case "Trago":
        return faCocktail;
      case "Comida":
        return faHamburger;
      case "Utensilio deportivo":
        return faBasketballBall;
      default:
        return faThumbsUp;
    }
  }

  componentDidMount() {
    let currentComponent = this;
    let parametrosLink = window.location.hash.substr(1).split("&");
    let cadenaLink = parametrosLink[0].split("=");
    //console.log("cadena: ",cadenaLink);
    //(window.adsbygoogle = window.adsbygoogle || []).push({});

    firebase
      .auth()
      .signInAnonymously()
      .catch(function (error) {
        // Errores a continuación.
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorMessage);
        //console.log(errorCode,errorMessage)
      });
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        //  se ha ingresado
        ReactGA.set({
          userId: user.uid,
          // any data that is relevant to the user session
          // that you would like to track with google analytics
        });

        //var isAnonymous = user.isAnonymous;
        //var uid = user.uid;
        //console.log("verificado!"+process.env.REACT_APP_CLARO_EJ)
      } else {
        // se ha salido
        //console.log("Se cerró sesión")
      }
    });

    if (cadenaLink[0] == "codigo" && cadenaLink[1] != "") {
      //console.log("cadena de codigo: ",cadenaLink[0],cadenaLink[1]);

      this.buscarDatosRifa(cadenaLink[1]);
    } else if (cadenaLink[0] == "") {
      db.collection("publicidad")
        .doc("sumario000")
        .get()
        .then(
          (docs) => {
            let arrayObjeto1 = [];
            let arrayObjeto2 = [];
            let arrayObjeto3 = [];
            Object.entries(docs.data()).map((i, j) => {
              if (i[1]["pais"].includes("todos")) {
                arrayObjeto1.push(i[0]);
              }
            });
            Object.entries(docs.data()).map((i, j) => {
              if (i[1]["pais"].includes("todos")) {
                arrayObjeto2.push(i[1]["link"]);
              }
            });
            Object.entries(docs.data()).map((i, j) => {
              if (i[1]["pais"].includes("todos")) {
                arrayObjeto3.push(i[1]["fichero"]);
              }
            });
            //console.log(arrayObjeto1, arrayObjeto2)
            currentComponent.setState({
              arrayAnuncios: arrayObjeto1,
              arrayLinks: arrayObjeto2,
              arrayFicheros: arrayObjeto3,
            });
            currentComponent.asignarAnuncios();
            //console.log("Datos anfitrion:",Object.keys(docs.data()))
          },
          (error) => {
            //console.log("Error al obtener datos:", error)
            this.message("Error al actualizar los datos del talonario");
          }
        );
      ReactGA.event({
        category: "visitaNOT",
        action: "Visita sin codigo",
      });
    } else {
      alert("Es necesario que accedas con el link que te brinda el anfitrion");
    }
    //(window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  handleAddDatosAdquirir(
    nombreParti,
    correoParti,
    confirmCelularParti,
    celularParti,
    spinnerNum,
    codigoPrev
  ) {
    this.asignarAnuncios();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card2" color="var(--color-backround)">
            <h2>
              ¿Estás seguro de adquirir la boleta{" "}
              {this.state.rifaTipoNum === 300 ||
              this.state.rifaTipoNum === 500 ||
              this.state.rifaTipoNum === 1000
                ? spinnerNum < 100
                  ? spinnerNum < 10
                    ? "00" + spinnerNum.toString()
                    : "0" + spinnerNum.toString()
                  : spinnerNum
                : spinnerNum < 10
                ? "0" + spinnerNum.toString()
                : spinnerNum}
              ?
            </h2>
            <p>
              Al ingresar tus datos aceptas los terminos y condiciones y
              políticas de privacidad...
            </p>
            <Row>
              <Col>
                <Terminos />
              </Col>
              <Col>
                {this.state.imagenAnuncioC && this.state.anunciosWeb && (
                  <VideoAnuncio
                    onClick={() => this.clickAnuncioV()}
                    imagenCargada={this.funcionImagenCargada}
                    linkAnuncio={this.state.linkAnuncioV}
                    imagenAnuncio={this.state.imagenAnuncioC}
                    entrada="imagen-ac"
                  />
                )}
              </Col>
            </Row>

            <Row>
              <br />
            </Row>
            <p> </p>
            <button
              className="boton-primario ml-4"
              onClick={() => {
                window.location.reload();
              }}
            >
              No
            </button>
            <button
              className="boton-primario ml-4"
              onClick={() => {
                this.FuningresarDatosAdquirir(
                  nombreParti,
                  correoParti,
                  confirmCelularParti,
                  celularParti,
                  spinnerNum,
                  codigoPrev
                );
                this.asignarAnuncios();
                onClose();
              }}
            >
              Si
            </button>
          </div>
        );
      },
    });
  }

  FuningresarDatosAdquirir(
    nombreParti,
    correoParti,
    confirmCelularParti,
    celularParti,
    spinnerNum,
    codigoPrev
  ) {
    //console.log(nombreParti, correoParti, confirmCelularParti, celularParti, spinnerNum, codigoPrev)
    let datosCliente = {
      nombre: nombreParti,
      correo: correoParti,
      telefonoConfirm: confirmCelularParti,
      celular: celularParti,
      spinner: spinnerNum,
      codigo: codigoPrev,
    };
    let currentComponent = this;
    //console.log("los datos del formulario son:", datosCliente)
    this.setState({
      clienteCelular: datosCliente.celular,
      clienteCorreo: datosCliente.correo,
      clienteCelularConfirm: datosCliente.telefonoConfirm,
      clienteNombre: datosCliente.nombre,
      numeroBoleta: datosCliente.spinner,
      verBoletasEnable: false,
      adquirirNueva: false,
      cargandoApp: true,
    });
    if (datosCliente.telefonoConfirm == datosCliente.celular) {
      //console.log("fecha anfitrion:", this.state.fechaAnfitrion)
      //console.log("fecha ahora:", firebase.firestore.Timestamp.now().toDate())
      if (
        this.state.fechaAnfitrionLast >=
        firebase.firestore.Timestamp.now().toDate()
      ) {
        //console.log("el correo es:", validator.isEmail(datosCliente.correo),datosCliente.correo)
        if (
          validator.isEmpty(datosCliente.nombre) ||
          validator.isEmpty(datosCliente.celular)
        ) {
          let errorNombre = "";
          let errorCorreo = "";
          let errorcelular = "";
          if (validator.isEmpty(datosCliente.nombre)) {
            errorNombre = ".Por favor ingresar tu nombre";
            currentComponent.setState({
              cargandoApp: false,
            });
          } else {
            errorNombre = "";
          }
          if (validator.isEmpty(datosCliente.celular)) {
            errorcelular = ".Por favor ingresar tu teléfono";
            currentComponent.setState({
              cargandoApp: false,
            });
          } else {
            errorcelular = "";
          }
          this.message(
            "Error de ingreso de datos " +
              errorNombre +
              errorCorreo +
              errorcelular
          );
        } else {
          //console.log("Correo antes: ",datosCliente.correo)
          /*if(!validator.isEmail(datosCliente.correo)){
              datosCliente.correo=datosCliente.correo.split(" ").join("")
              //console.log("no se ha validado el correo 1 vez",datosCliente.correo)
              if(!validator.isEmail(datosCliente.correo)){
                datosCliente.correo="NO REGISTRADO"
                //console.log("no se pudo validar el correo, sera null",datosCliente.correo)
              }
            }*/
          //console.log("El correo se guarda asi: ",datosCliente.correo)

          var obRuta = {};
          var obRuta1 = {
            fecha: firebase.firestore.Timestamp.now().toDate(),
            estado: "-",
          };
          obRuta["libres." + datosCliente.spinner + "a"] = obRuta1;
          //console.log("objeto:", obRuta)

          var obRutaBoleSingle = {};
          obRutaBoleSingle[datosCliente.spinner] = "-";
          //console.log("objeto:", obRutaBoleSingle)

          var obRutaPersonal = {};
          obRutaPersonal[datosCliente.spinner] = {
            nombre: datosCliente.nombre,
            celular: datosCliente.celular,
            correo: datosCliente.correo,
          };
          //console.log("objeto:", obRutaPersonal)

          var batch = db.batch();
          // asignar la boleta a un correo
          let docRefCorreo = db
            .collection("datos")
            .doc(datosCliente.codigo)
            .collection("obtenidos")
            .doc(datosCliente.celular);
          let docRefPeople = db
            .collection("datos")
            .doc(datosCliente.codigo)
            .collection("masinfo")
            .doc("people");
          let docRefPrincipal = db.collection("datos").doc(datosCliente.codigo);
          // ingresar la boleta como apartada
          batch.update(docRefPrincipal, obRuta);

          docRefCorreo
            .get()
            .then((doc2) => {
              if (doc2.exists) {
                // ingresar la boleta como apartada
                batch.update(docRefPrincipal, obRuta);
                batch.update(docRefPeople, obRutaPersonal);
                batch.update(docRefCorreo, obRutaBoleSingle);
              } else {
                // ingresar la boleta como apartada
                batch.update(docRefPrincipal, obRuta);
                batch.update(docRefPeople, obRutaPersonal);
                batch.set(docRefCorreo, obRutaBoleSingle);
              }

              batch
                .commit()
                .then(function () {
                  if (!currentComponent.state.anunciosWeb) {
                    //console.log("SIN ANUNCIOS ADQUIERE")
                    ReactGA.event({
                      category: "Adquirir0",
                      action: "Adquisicion completada sin anuncios",
                    });
                  } else {
                    //console.log("CON ANUNCIOS ADQUIERE")
                    ReactGA.event({
                      category: "Adquirir",
                      action: "Adquisicion completada con anuncios",
                    });
                  }
                  //console.log("Documento de ver boletas actualizado");
                  window.scrollTo(0, 1000);
                  currentComponent.message(
                    "has adquirido la boleta #" + datosCliente.spinner
                  );
                  currentComponent.cargarDatos(
                    datosCliente.codigo,
                    datosCliente.celular
                  );
                  currentComponent.setState({
                    cargandoApp: false,
                  });
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                  alert("Error subiendo datos, revisar conexión");
                  currentComponent.setState({
                    cargandoApp: false,
                  });
                });
            })
            .catch(function (error) {
              alert("Error al obtener el número:", error);
              currentComponent.setState({
                cargandoApp: false,
              });
            });
        }
      } else {
        this.message(
          "Ya no se pueden adquirir más boletas, el sorteo/concurso ya jugó"
        );
        currentComponent.setState({
          cargandoApp: false,
        });
      }
    } else {
      this.message(
        "Corrige los campos de tu número de teléfono, deben ser iguales"
      );
      currentComponent.setState({
        cargandoApp: false,
      });
    }
  }

  asignarAnuncios() {
    let currentComponent = this;
    let arrayCarpetas = this.state.arrayAnuncios;
    let arrayLinks = this.state.arrayLinks;
    let arrayFicheros = this.state.arrayFicheros;
    let maxR = arrayCarpetas.length;
    let carpetaRandom = 0;
    let linkRandom = 0;
    let randCoef = 0.0;
    let ficheroRandom = 0;
    randCoef = Math.random();
    carpetaRandom = arrayCarpetas[Math.floor(randCoef * maxR)];
    linkRandom = arrayLinks[Math.floor(randCoef * maxR)];
    ficheroRandom = arrayFicheros[Math.floor(randCoef * maxR)];
    //console.log("El numero de anuncio id es: ",carpetaRandom," ",linkRandom)
    let arrayDivisiones = ["", "", ""];
    let arrayImagenes = ["", "", "", ""];

    if (linkRandom) {
      arrayDivisiones = linkRandom.split("|");
    }
    if (ficheroRandom) {
      arrayImagenes = ficheroRandom.split("|");
    }
    currentComponent.setState({
      imagenAnuncioH: "/bloques/" + carpetaRandom + "/" + arrayImagenes[2],
      imagenAnuncioC: "/bloques/" + carpetaRandom + "/" + arrayImagenes[3],
      imagenAnuncioV1: "/bloques/" + carpetaRandom + "/" + arrayImagenes[0],
      imagenAnuncioV2: "/bloques/" + carpetaRandom + "/" + arrayImagenes[1],
      imagenAnuncioG: "/bloques/" + carpetaRandom + "/" + arrayImagenes[3],
      imagenAnuncioE: "/bloques/" + carpetaRandom + "/" + arrayImagenes[3],
      linkAnuncioV: "https://" + arrayDivisiones[0],
      linkAnuncioFH: "https://" + arrayDivisiones[1],
      linkAnuncioFV: "https://" + arrayDivisiones[2],
      publicidadActual: carpetaRandom,
    });
  }

  clickAnuncioV() {
    //console.log("click en el anuncio generado.", this.state.linkAnuncio)
    var clicksRef = db
      .collection("publicidad")
      .doc(this.state.publicidadActual);

    return db
      .runTransaction(function (transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(clicksRef).then(function (anuncioRef) {
          let fecha_1 = firebase.firestore.Timestamp.now().toDate();
          let fecha1 =
            fecha_1.getFullYear() +
            "-" +
            (fecha_1.getMonth() + 1) +
            "-" +
            fecha_1.getDate() +
            ":Video";
          //console.log("Fecha de acceso2: ", fecha1)

          if (!anuncioRef.exists) {
            var obAnuncio = {};
            obAnuncio[fecha1] = 0;
            //console.log("anuncio trasnaccion: ", obAnuncio, anuncioRef)
            transaction.set(anuncioRef, obAnuncio);
            //throw "El documento no se ha creado";
          } else {
            let aux_anuncio = anuncioRef.data()[fecha1];
            var obAnuncio = {};
            if (isNaN(aux_anuncio) || aux_anuncio == null) {
              obAnuncio[fecha1] = 1;
            } else {
              obAnuncio[fecha1] = 1 + aux_anuncio;
            }
            transaction.update(clicksRef, obAnuncio);
          }
        });
      })
      .then(function () {
        //console.log("Transaction successfully committed!");
      })
      .catch(function (error) {
        //console.log("Transaction failed: ", error);
      });
  }

  clickAnuncioFH() {
    //console.log("click en el anuncio generado.", this.state.linkAnuncio)
    var clicksRef = db
      .collection("publicidad")
      .doc(this.state.publicidadActual);

    return db
      .runTransaction(function (transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(clicksRef).then(function (anuncioRef) {
          let fecha_1 = firebase.firestore.Timestamp.now().toDate();
          let fecha1 =
            fecha_1.getFullYear() +
            "-" +
            (fecha_1.getMonth() + 1) +
            "-" +
            fecha_1.getDate() +
            ":Horizontal";
          //console.log("Fecha de acceso2: ", fecha1)

          if (!anuncioRef.exists) {
            var obAnuncio = {};
            obAnuncio[fecha1] = 0;
            //console.log("anuncio trasnaccion: ", obAnuncio, anuncioRef)
            transaction.set(anuncioRef, obAnuncio);
            //throw "El documento no se ha creado";
          } else {
            let aux_anuncio = anuncioRef.data()[fecha1];
            var obAnuncio = {};
            if (isNaN(aux_anuncio) || aux_anuncio == null) {
              obAnuncio[fecha1] = 1;
            } else {
              obAnuncio[fecha1] = 1 + aux_anuncio;
            }
            transaction.update(clicksRef, obAnuncio);
          }
        });
      })
      .then(function () {
        //console.log("Transaction successfully committed!");
      })
      .catch(function (error) {
        //console.log("Transaction failed: ", error);
      });
  }

  clickAnuncioFV() {
    //console.log("click en el anuncio generado.", this.state.linkAnuncio)
    var clicksRef = db
      .collection("publicidad")
      .doc(this.state.publicidadActual);

    return db
      .runTransaction(function (transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(clicksRef).then(function (anuncioRef) {
          let fecha_1 = firebase.firestore.Timestamp.now().toDate();
          let fecha1 =
            fecha_1.getFullYear() +
            "-" +
            (fecha_1.getMonth() + 1) +
            "-" +
            fecha_1.getDate() +
            ":Vertical";
          //console.log("Fecha de acceso2: ", fecha1)

          if (!anuncioRef.exists) {
            var obAnuncio = {};
            obAnuncio[fecha1] = 0;
            //console.log("anuncio trasnaccion: ", obAnuncio, anuncioRef)
            transaction.set(anuncioRef, obAnuncio);
            //throw "El documento no se ha creado";
          } else {
            let aux_anuncio = anuncioRef.data()[fecha1];
            var obAnuncio = {};
            if (isNaN(aux_anuncio) || aux_anuncio == null) {
              obAnuncio[fecha1] = 1;
            } else {
              obAnuncio[fecha1] = 1 + aux_anuncio;
            }
            transaction.update(clicksRef, obAnuncio);
          }
        });
      })
      .then(function () {
        //console.log("Transaction successfully committed!");
      })
      .catch(function (error) {
        //console.log("Transaction failed: ", error);
      });
  }

  handleAddDatosVer(telefonoCliente) {
    //console.log(telefonoCliente.isVerificado1)
    if (telefonoCliente.isVerificado1) {
      this.setState({
        clienteCelular: telefonoCliente.celular,
        cargandoApp: true,
      });
      this.cargarDatos(telefonoCliente.codigo, telefonoCliente.celular);
    } else {
      this.message("Verifica la casilla reCAPTCHA para continuar");
    }
  }

  cargarDatos(codigo, celular) {
    let currentComponent = this;
    currentComponent.setState({
      verBoletasEnable: false,
    });
    if (!validator.isEmpty(codigo) && !validator.isEmpty(celular)) {
      db.collection("datos")
        .doc(codigo)
        .collection("obtenidos")
        .doc(celular)
        .get()
        .then(
          function (querySnapshot) {
            if (querySnapshot.exists) {
              if (
                currentComponent.state.adquirirNueva &&
                currentComponent.state.anunciosWeb
              ) {
                currentComponent.asignarAnuncios();
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="card2" color="var(--color-backround)">
                        <a
                          href={currentComponent.state.linkAnuncioV}
                          onClick={() => currentComponent.clickAnuncioV()}
                          className="boton-primario"
                          target="_blank"
                        >
                          ¡Conoce más!
                        </a>
                        <button
                          className="boton-cerrar"
                          onClick={() => {
                            currentComponent.asignarAnuncios();
                            onClose();
                          }}
                        >
                          x
                        </button>
                        <Row>
                          <Col>
                            {currentComponent.state.imagenAnuncioG && (
                              <VideoAnuncio
                                onClick={() => currentComponent.clickAnuncioV()}
                                imagenCargada={
                                  currentComponent.funcionImagenCargada
                                }
                                linkAnuncio={
                                  currentComponent.state.linkAnuncioV
                                }
                                imagenAnuncio={
                                  currentComponent.state.imagenAnuncioG
                                }
                                entrada="imagen-ag"
                              />
                            )}
                          </Col>
                        </Row>
                        <br />
                      </div>
                    );
                  },
                });
              }

              //console.log("El objeto de boletas: ", currentComponent.state.arrayBoletas)
              //console.log("Los datos recopilados son: ",querySnapshot.data())
              if (currentComponent.state.adquirirNueva) {
                if (!currentComponent.state.anunciosWeb) {
                  //console.log("SIN ANUNCIOS VISUALIZA")
                  ReactGA.event({
                    category: "Visualizacion0",
                    action: "Consulta de boletas sin anuncios",
                  });
                } else {
                  //console.log("CON ANUNCIOS VISUALIZA")
                  ReactGA.event({
                    category: "Visualizacion",
                    action: "Consulta de boletas con anuncios",
                  });
                }
              }

              //console.log("la rifa tiene datos: ",querySnapshot.data())
              currentComponent.setState({
                verBoletasEnable: true,
                clienteDatosNumeros: querySnapshot.data(),
                cargandoApp: false,
                clienteDatosRifa: Object.entries(querySnapshot.data()).map(
                  ([key, value]) => {
                    //console.log("key:",key);
                    //console.log("value:",currentComponent.state.arrayBoletas[key+"a"]);
                    //return {id:key,data:value}
                    return {
                      id: key,
                      data: currentComponent.state.arrayBoletas[key + "a"],
                    };
                  }
                ),
              });
              window.scrollTo(0, 1000);
              currentComponent.todoAnfi(currentComponent.state.codigoInput);
            } else {
              currentComponent.message(
                "No existen boletas vinculadas a este número de teléfono."
              );
              currentComponent.setState({
                verBoletasEnable: false,
                cargandoApp: false,
              });
            }
          },
          (error) => {
            //console.log("Error al actualizar datos:", error)
            currentComponent.message(
              "Error al obtener boletas, posiblemente se ha introducido un número de teléfono incorrecto."
            );
            currentComponent.setState({
              cargandoApp: false,
            });
          }
        );
    } else {
      this.message("Falta ingresar datos");
      currentComponent.setState({
        cargandoApp: false,
      });
    }
  }

  handleVerBoletas() {
    this.asignarAnuncios();
    this.setState({
      pasoDescripción: "Presiona y elige una opción: ",
      habilitarModo: true,
      verBoletasEnable: false,
      spinnerInput: 2,
    });
    //console.log("spinnerInput: ",this.state.spinnerInput)
    window.scrollTo(0, document.getElementById("contenedor").offsetTop);
  }

  handleAdquerirBoletas() {
    this.asignarAnuncios();
    this.setState({
      pasoDescripción: "Presiona y elige una opción: ",
      habilitarModo: true,
      verBoletasEnable: false,
      spinnerInput: 1,
    });
    //console.log("spinnerInput: ",this.state.spinnerInput)
    window.scrollTo(0, document.getElementById("contenedor").offsetTop);
    this.cargandoRefM.current.textContent = " ";
    this.cargandoRefM.current.classList.remove(`loader`);
  }

  buscarDatosRifa(codigoIntroducido) {
    //console.log(codigoIntroducido)
    this.setState({
      pasoDescripción: "Se está cargando la información...",
      codigoInput: codigoIntroducido,
      cargandoApp: true,
    });
    if (codigoIntroducido == this.state.codigoRifa) {
      //console.log("no es necesario re actualizar");
    } else {
      this.actualizarAnfitrion(codigoIntroducido);
    }
  }

  actualizarAnfitrion(codigo) {
    const currentComponent = this;
    if (codigo != "" && codigo != " ") {
      currentComponent.todoAnfi(codigo);
      const docRef = db.collection("datos").doc(codigo);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            //console.log("existe!")
            docRef.onSnapshot(
              (snapShots) => {
                //console.log("snapshot: ",snapShots.data())
                //console.log("correo: ",snapShots.data().correo)
                //console.log("libres: ",snapShots.data().libres)
                let fechaAuxiliar = snapShots.data().fecha.toDate();
                fechaAuxiliar.setDate(fechaAuxiliar.getDate() + 1);
                //console.log("fecha actual", snapShots.data().fecha.toDate())
                //console.log("fecha auxiliar",fechaAuxiliar)
                var auxPais = snapShots.data().pais;
                if (
                  snapShots.data().pais === "null" ||
                  !snapShots.data().pais
                ) {
                  auxPais = "Colombia";
                }

                this.setState({
                  nombreAnfitrion: snapShots.data().nombre,
                  fechaAnfitrion: snapShots.data().fecha.toDate(),
                  fechaAnfitrionLast: fechaAuxiliar,
                  premioAnfitrion: snapShots.data().premio,
                  linkAnfitrion: snapShots.data().link,
                  estadoRifa: snapShots.data().estado,
                  valorBoleta: snapShots.data().valor,
                  currencyAux: snapShots.data().currency,
                  ganador: snapShots.data().ganador,
                  tipoRifa: snapShots.data().tipo,
                  arrayBoletas: snapShots.data().libres,
                  conteosSorteo: snapShots.data().conteos,
                  rangoSorteo: snapShots.data().rango,
                  colores: snapShots.data().colores,
                  redes: snapShots.data().redes,
                  slogan: snapShots.data().slogan,
                  subscrito: snapShots.data().subscrito,
                  fotoPremio: snapShots.data().fotoPremio,
                  pais: auxPais,
                  codigoRifa: codigo,
                  existeRifa: true,
                  pasoDescripción: "Presiona y elige una opción: ",
                  cargandoApp: false,
                  rifaTipoNum: snapShots.data().rifaTipo,
                }); //datos del juego que se retienen
                //console.log("estado de rif. ", snapShots.data().estado)
                if (snapShots.data().estado === "3") {
                  this.message(
                    "Este talonario no se encuentra disponible, el responsable lo ha cancelado."
                  );
                }
                //console.log("ganador", typeof(this.state.ganador))
                //console.log("pais de anfitrion: ", paises.[auxPais], snapShots.data().pais )
                db.collection("publicidad")
                  .doc("sumario000")
                  .get()
                  .then(
                    (docs) => {
                      let arrayObjeto1 = [];
                      let arrayObjeto2 = [];
                      let arrayObjeto3 = [];
                      Object.entries(docs.data()).map((i, j) => {
                        if (
                          i[1]["pais"].includes(auxPais) ||
                          i[1]["pais"].includes("todos")
                        ) {
                          arrayObjeto1.push(i[0]);
                        }
                      });
                      Object.entries(docs.data()).map((i, j) => {
                        if (
                          i[1]["pais"].includes(auxPais) ||
                          i[1]["pais"].includes("todos")
                        ) {
                          arrayObjeto2.push(i[1]["link"]);
                        }
                      });
                      Object.entries(docs.data()).map((i, j) => {
                        if (
                          i[1]["pais"].includes(auxPais) ||
                          i[1]["pais"].includes("todos")
                        ) {
                          arrayObjeto3.push(i[1]["fichero"]);
                        }
                      });
                      //console.log(arrayObjeto1, arrayObjeto2)
                      currentComponent.setState({
                        arrayAnuncios: arrayObjeto1,
                        arrayLinks: arrayObjeto2,
                        arrayFicheros: arrayObjeto3,
                      });
                      currentComponent.asignarAnuncios();
                      //console.log("Datos anfitrion:",Object.keys(docs.data()))
                    },
                    (error) => {
                      //console.log("Error al obtener datos:", error)
                      this.message(
                        "Error al actualizar los datos del talonario"
                      );
                    }
                  );

                if (!snapShots.data().adiciones) {
                  let infoDetalleAux =
                    "Adquiere las boletas que desees, ¡Gracias por tu apoyo!";
                  this.setState({
                    infoDetalle: infoDetalleAux,
                  });
                } else {
                  if (validator.isEmpty(snapShots.data().adiciones)) {
                    let infoDetalleAux =
                      "Adquiere las boletas que desees, ¡Gracias por tu apoyo!";
                    this.setState({
                      infoDetalle: infoDetalleAux,
                    });
                  } else {
                    this.setState({
                      infoDetalle: snapShots.data().adiciones,
                    });
                  }
                }

                if (snapShots.data().subscrito) {
                  //this.getImage()
                  //console.log("colores", snapShots.data().colores[1])
                  this.setState({
                    imagenLogo:
                      process.env.PUBLIC_URL + "/" + snapShots.data().marca,
                    marcaRifa: snapShots.data().marca,
                  });
                  document.documentElement.style.setProperty(
                    "--color-primario",
                    snapShots.data().colores[0]
                  );
                  document.documentElement.style.setProperty(
                    "--color-secundario",
                    snapShots.data().colores[1]
                  );
                  document.documentElement.style.setProperty(
                    "--color-backround",
                    snapShots.data().colores[2]
                  );
                  document.documentElement.style.setProperty(
                    "--color-claro",
                    snapShots.data().colores[3]
                  );
                  document.documentElement.style.setProperty(
                    "--color-efecto",
                    snapShots.data().colores[4]
                  );
                  //2,0,4,1,3
                }
                if (typeof snapShots.data().rango != "undefined") {
                  this.setState({
                    habilitarModo: false,
                    habilitarSorteo: true,
                    pasoDescripción:
                      "Este sorteo se realizó desde la aplicación RifaYa",
                  });
                } else {
                  this.setState({
                    habilitarModo: true,
                    habilitarSorteo: false,
                  });
                }
              },
              (error) => {
                //console.log("Error al actualizar datos:", error)
                this.message("Error al actualizar los datos del talonario");
                this.setState({
                  existeRifa: false,
                  habilitarModo: false,
                  correoAnfitrion: "",
                  celularAnfitrion: "",
                  nombreAnfitrion: "",
                  fechaAnfitrion: "",
                  fechaAnfitrionLast: "",
                  premioAnfitrion: "",
                  linkAnfitrion: "",
                  estadoRifa: "",
                  valorBoleta: "",
                  currencyAux: "",
                  ganador: "",
                  tipoRifa: "",
                  cargandoApp: false,
                }); //datos del juego que se retienen
              }
            );
          } else {
            //console.log("No existe el documento");
            this.message("Este talonario ya no está disponible");
            this.setState({
              existeRifa: false,
              habilitarModo: false,
              correoAnfitrion: "",
              celularAnfitrion: "",
              nombreAnfitrion: "",
              fechaAnfitrion: "",
              fechaAnfitrionLast: "",
              premioAnfitrion: "",
              linkAnfitrion: "",
              estadoRifa: "",
              valorBoleta: "",
              currencyAux: "",
              ganador: "",
              tipoRifa: "",
              pasoDescripción:
                "Este link no es valido, comunicate con el anfitrión del talonario para que te brinde un link válido.",
              cargandoApp: false,
            }); //datos del juego que se retienen
          }
        })
        .catch(function (error) {
          alert(
            "No se ha podido establecer conexión con el servidor, actualiza la página o prueba tu conexión a internet"
          );
          //console.log("Error al obtener el documento:", error);
          currentComponent.setState({
            cargandoApp: false,
            pasoDescripción: "Recarga ésta página con conexión a internet",
          });
        });
      var vivistasRef = db
        .collection("datos")
        .doc(codigo)
        .collection("masinfo")
        .doc("visitas");

      return db
        .runTransaction(function (transaction) {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(vivistasRef).then(function (visiRef) {
            let fecha_1 = firebase.firestore.Timestamp.now().toDate();
            let fecha1 =
              fecha_1.getFullYear() +
              "-" +
              (fecha_1.getMonth() + 1) +
              "-" +
              fecha_1.getDate();
            //console.log("Fecha de acceso3: ", fecha1)

            if (!visiRef.exists) {
              var obVisitas = {};
              obVisitas[fecha1] = 0;
              //console.log(obVisitas)
              transaction.set(vivistasRef, obVisitas);
              //throw "El documento no se ha creado";
            } else {
              let aux_visitas = visiRef.data()[fecha1];
              var obVisitas = {};
              if (isNaN(aux_visitas) || aux_visitas == null) {
                //console.log("Fecha no creada: ",aux_visitas)
                obVisitas[fecha1] = 1;
              } else {
                obVisitas[fecha1] = 1 + aux_visitas;
                //console.log("Visita Actualizada: ",obVisitas[fecha1])
              }
              transaction.update(vivistasRef, obVisitas);
            }
          });
        })
        .then(function () {
          //console.log("Transaction successfully committed!");
        })
        .catch(function (error) {
          //console.log("Transaction failed: ", error);
        });
    }
  }

  message(message) {
    this.setState({
      fadeln: true,
      message: message,
    });

    setTimeout(() => {
      this.setState({
        fadeln: false,
        message: "",
      });
    }, 5000);
  }

  estadoBoleta(estadoB) {
    if (estadoB == "-") {
      return "en deuda";
    } else if (estadoB == "--") {
      return "pagada";
    }
  }

  funcionImagenCargada() {
    ReactGA.event({
      category: "impresiones-" + this.state.publicidadActual,
      action: "carga anuncios " + this.state.publicidadActual,
    });
    //console.log("imagen cargada: ", this.state.publicidadActual)
  }

  todoAnfi(codigo) {
    var todo = "";
    let correo = "";
    //console.log("el codigo es:", codigo)
    db.collection("datos")
      .doc(codigo)
      .collection("masinfo")
      .doc("rifante")
      .get()
      .then(
        (docs) => {
          if (!docs.data()) {
          } else {
            //todo="Celular: "+docs.data().celular+" Correo:"+docs.data().correo
            todo = "Celular: " + docs.data().celular;
            correo = docs.data().correo;

            //console.log("Datos anfitrion:",todo)
            this.setState({
              todoA: todo,
            });
            let cont = 0;
            if (correo) {
              /* const docAnuncio=db.collection("anunciosWeb0")
      docAnuncio.where("correo", "==", correo)
      .get()
      .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //console.log(doc.id, " => ", doc.data());
              this.setState({
                anunciosWeb:false
              })
              cont=cont+1
          });
          if(cont>0){
            //console.log("SIN ANUNCIOS Y CODIGO")
            ReactGA.event({
               category: 'visitaCOD0',
               action: 'Visita con codigo y sin anuncios'
             });
          }else{
            if(this.state.anunciosWeb){
              ReactGA.event({
                 category: 'visitaCOD',
                 action: 'Visita con codigo y con anuncios'
               });
            }
          }
      })
      .catch((error) => {
          //console.log("Error al obtener datos del anfitrión. ",error);
          this.message('Error al obtener datos del anfitrión responsable.')
      }); */
            } else {
              //console.log("VISTA SORTEAYA")
              ReactGA.event({
                category: "visitaSORTEAYA",
                action: "Visita con codigo a sorteaya",
              });
            }
          }
        },
        (error) => {
          //console.log("Error al actualizar datos:", error)
          this.message("Error al obtener datos del anfitrión responsable.");
        }
      );
  }

  borrarBoleta(numBoleta) {
    let currentComponent = this;
    //console.log(numBoleta)
    //console.log(this.state.codigoInput)

    //console.log(this.state.clienteDatosRifa)
    this.setState({
      clienteDatosRifa: this.state.clienteDatosRifa.filter((e, i) => {
        return e.id != numBoleta;
      }),
    });
    //console.log(this.state.clienteDatosRifa)

    var obRuta = {};
    var obRuta1 = {
      fecha: firebase.firestore.Timestamp.now().toDate(),
      estado: numBoleta,
    };
    obRuta["libres." + numBoleta + "a"] = obRuta1;

    var numeroCliente = this.state.clienteDatosNumeros;
    delete numeroCliente[numBoleta];

    var boletasPeople = {};
    boletasPeople[numBoleta] = { estado: "sin asignación" };

    var batch = db.batch();

    var refRifaBoletas = db.collection("datos").doc(this.state.codigoInput);
    batch.update(refRifaBoletas, obRuta);

    var refBoletaCliente = db
      .collection("datos")
      .doc(this.state.codigoInput)
      .collection("obtenidos")
      .doc(this.state.clienteCelular);
    batch.set(refBoletaCliente, numeroCliente);

    var refPersonalCliente = db
      .collection("datos")
      .doc(currentComponent.state.codigoInput)
      .collection("masinfo")
      .doc("people");
    batch.update(refPersonalCliente, boletasPeople);

    batch
      .commit()
      .then(() => {
        currentComponent.message("Boleta descartada");
      })
      .catch(() => {
        this.message("Error descartando datos");
      });
  }
  // onClick={this.poner100datos}

  //componentes que se visualizan
  render() {
    const boletas = this.state.clienteDatosRifa.map((boleta, i) => {
      return (
        <Col
          sm={{ size: ((i + 1) % 2) + 1, offset: ((i + 1) % 2) + 2 }}
          key={i}
        >
          <div className="card1">
            <div className="card-title text-center">
              <h5>
                <b> Teléfono del participante:</b>
              </h5>
              <h6> {this.state.clienteCelular}</h6>
            </div>
            <div className="card-footer">
              <h4>
                Boleta {this.estadoBoleta(boleta.data.estado)}
                <div>
                  {this.state.ganador == boleta.id && (
                    <div className="parpadea text">
                      <strong>¡Felicidades, esta es la boleta Ganadora!</strong>
                    </div>
                  )}
                  <button
                    onClick={() => {
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <div
                              className="card2"
                              color="var(--color-backround)"
                            >
                              <h2>Datos de contacto del responsable:</h2>
                              <p>
                                Por este medio podrás contactar al responsable
                                del talonario: {this.state.todoA}
                              </p>
                              <button
                                className="boton-primario"
                                onClick={() => {
                                  onClose();
                                }}
                              >
                                Entendido
                              </button>
                            </div>
                          );
                        },
                      });
                    }}
                    className="boton-eleccion"
                    style={{
                      width: "120px",
                      height: "fit-content",
                      fontSize: "1vh",
                      paddingBottom: "1vh",
                    }}
                  >
                    Contacto del responsable del talonario
                  </button>
                </div>
              </h4>
              <br />
              {boleta.data.estado == "-" && (
                <button
                  onClick={() => {
                    confirmAlert({
                      customUI: ({ onClose }) => {
                        return (
                          <div className="card2" color="var(--color-backround)">
                            <h2>¿Estás seguro de liberar la boleta?</h2>
                            <p>
                              Al hacerlo liberas la boleta para que se pueda
                              volver a adquirir.
                            </p>
                            <button
                              className="boton-primario"
                              onClick={() => {
                                onClose();
                              }}
                            >
                              No
                            </button>
                            <button
                              className="boton-primario"
                              onClick={() => {
                                this.borrarBoleta(boleta.id);
                                onClose();
                              }}
                            >
                              Si
                            </button>
                          </div>
                        );
                      },
                    });
                  }}
                  className="btn"
                  style={{
                    backgroundColor: "var(--color-secundario)",
                    color: "var(--color-claro)",
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} fixedWidth size="3x" />
                  <u>Presionar para descartar boleta</u>
                </button>
              )}
            </div>
            <h2>
              <span
                className="badge badge-pill"
                style={{
                  backgroundColor: "var(--color-secundario)",
                  color: "var(--color-claro)",
                  textAlign: "center",
                }}
              >
                #
                {this.state.rifaTipoNum === 300 ||
                this.state.rifaTipoNum === 500 ||
                this.state.rifaTipoNum === 1000
                  ? boleta.id < 100
                    ? boleta.id < 10
                      ? "00" + boleta.id.toString()
                      : "0" + boleta.id.toString()
                    : boleta.id
                  : boleta.id < 10
                  ? "0" + boleta.id.toString()
                  : boleta.id}
              </span>
            </h2>
            <div className="card-body">
              <h5>
                <b>Fecha de adquisición de la boleta: </b>
                {boleta.data.fecha.toDate().toLocaleDateString() +
                  " " +
                  boleta.data.fecha.toDate().toLocaleTimeString()}
              </h5>
            </div>
          </div>
        </Col>
      );
    });

    return (
      <div className="App">
        {this.state.showDetails && (
          <StickyHeader
            // This is the sticky part of the header.
            header={
              <div className="sidebar">
                <BrowserView>
                  <nav className="navbar navbar-dark bg-dark">
                    {!this.state.fadeln &&
                      this.state.premioAnfitrion != "" &&
                      this.state.fechaAnfitrion != "" &&
                      this.state.linkAnfitrion != "" &&
                      this.state.nombreAnfitrion != "" && (
                        <div>
                          <Row>
                            <div className="card-header">
                              <Row>
                                <Col xs="auto">
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={this.imagenTipoRifa()}
                                    fixedWidth
                                    size="3x"
                                  />
                                </Col>
                                <Col xs="auto">
                                  <div className="header-info">
                                    <strong> Premio </strong>
                                    <Linkify
                                      properties={{
                                        target: "_blank",
                                        style: {
                                          color: "green",
                                          fontWeight: "bold",
                                        },
                                      }}
                                    >
                                      <div className="lead-aux">
                                        {this.state.premioAnfitrion}
                                      </div>
                                    </Linkify>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="card-header">
                              <Row>
                                <Col xs="auto">
                                  <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    fixedWidth
                                    size="3x"
                                  />
                                </Col>
                                <Col xs="auto">
                                  <div className="header-info">
                                    <strong> ¿Cuándo juega? </strong>
                                    <div className="lead-aux">
                                      {" "}
                                      {this.state.fechaAnfitrion
                                        .toISOString()
                                        .replace(/T/, " ")
                                        .replace(/\..+/, "")
                                        .substring(0, 10)}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {typeof this.state.rangoSorteo == "undefined" && (
                              <div className="card-header">
                                <Row>
                                  {" "}
                                  <Col xs="auto">
                                    <FontAwesomeIcon
                                      icon={faDollarSign}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info">
                                      <strong> Valor </strong>
                                      <div className="lead-aux">
                                        {this.state.valorBoleta}
                                      </div>
                                      {` ${
                                        this.state.currencyAux ??
                                        paises[this.state.pais]
                                      }`}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {typeof this.state.rangoSorteo == "undefined" && (
                              <div className="card-header">
                                <Row>
                                  <Col xs="auto">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faExternalLinkAlt}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info">
                                      <strong> Premio mayor de </strong>{" "}
                                      <u>
                                        <div className="lead-aux">
                                          {this.state.linkAnfitrion}
                                        </div>
                                      </u>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {typeof this.state.rangoSorteo != "undefined" && (
                              <div className="card-header">
                                <Row>
                                  <Col xs="auto">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faRandom}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info">
                                      <strong>
                                        {" "}
                                        El sorteo jugó de{" "}
                                        {this.state.rangoSorteo} con{" "}
                                        {this.state.conteosSorteo} intento(s) de
                                        lanzamiento de balotas.
                                      </strong>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {typeof this.state.ganador != "undefined" && (
                              <div className="card-header">
                                <Row>
                                  <Col xs="auto">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faAward}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info">
                                      <strong> Ganador </strong>{" "}
                                      <u>
                                        <div className="lead-aux">
                                          El número{" "}
                                          {this.state.rifaTipoNum === 300 ||
                                          this.state.rifaTipoNum === 500 ||
                                          this.state.rifaTipoNum === 1000
                                            ? this.state.ganador < 100
                                              ? this.state.ganador < 10
                                                ? "00" +
                                                  this.state.ganador.toString()
                                                : "0" +
                                                  this.state.ganador.toString()
                                              : this.state.ganador
                                            : this.state.ganador < 10
                                            ? "0" +
                                              this.state.ganador.toString()
                                            : this.state.ganador}
                                        </div>
                                      </u>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            <div className="card-header">
                              <Row>
                                {" "}
                                <Col xs="auto">
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    fixedWidth
                                    size="3x"
                                  />
                                </Col>
                                <Col xs="auto">
                                  <div className="header-info">
                                    <strong> Responsable </strong>{" "}
                                    <div className="lead-aux">
                                      {this.state.nombreAnfitrion}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {this.state.nombreAnfitrion != "" &&
                              typeof this.state.ganador == "undefined" && (
                                <div>
                                  <Row>
                                    <Col
                                      xs={{ size: 6, offset: 1 }}
                                      md={{ size: 6, offset: 1 }}
                                      xl={{ size: 6, offset: 1 }}
                                    >
                                      <div className="lead-aux-2">
                                        ¡Crea tu propio talonario!
                                      </div>
                                    </Col>
                                    <Col
                                      xs={{ size: 4 }}
                                      md={{ size: 4 }}
                                      xl={{ size: 4 }}
                                    >
                                      <div>
                                          <Button
                                            styles={{
                                              backgroundColor:
                                                "var(--color-secundario)",
                                              color: "var(--color-backround)",
                                            }}
                                            rotate={true}
                                            tooltip="¡Instala la app!"
                                            onClick={()=>{confirmAlert({
                                              customUI: ({ onClose }) => {
                                               return (
                                                 <div className='card2' color= "var(--color-backround)" >
                                                   <InstallApp/>
                                                   <button className="boton-primario"
                                                     onClick={() => {
                                                       onClose();
                                                     }}
                                                   >
                                                     Entendido
                                                   </button>
                                                 </div>
                                               );
                                              }
                                              });}}
                                          >
                                            <FontAwesomeIcon
                                              icon={faDownload}
                                              size="2x"
                                            />
                                          </Button>
                                      </div>
                                    </Col>
                                  </Row>{" "}
                                </div>
                              )}
                          </Row>
                        </div>
                      )}

                    {this.state.fadeln && (
                      <ul>
                        <Fade
                          in={this.state.fadeln}
                          tag="h2"
                          className="mt-2 text-center text-sucess"
                        >
                          {this.state.message}
                        </Fade>
                      </ul>
                    )}
                    {!this.state.habilitarSorteo &&
                      this.state.fechaActual.toString().substring(0, 15) ===
                        this.state.fechaAnfitrion
                          .toString()
                          .substring(0, 15) && (
                        <h3>
                          Recuerda que esta rifa/concurso estará en curso hoy.
                        </h3>
                      )}
                    {(!this.state.habilitarSorteo &&
                      this.state.spinnerInput == 1 &&
                      this.state.fechaActual >=
                        this.state.fechaAnfitrionLast) ||
                      (!this.state.habilitarSorteo &&
                        this.state.existeRifa &&
                        this.state.fechaActual >=
                          this.state.fechaAnfitrionLast && (
                          <h3>
                            La rifa/concurso ya finalizó, no es posible adquirir
                            más boletas.
                          </h3>
                        ))}
                  </nav>
                </BrowserView>
                <MobileView>
                  <nav className="navbar navbar-dark bg-dark">
                    {!this.state.fadeln &&
                      this.state.premioAnfitrion != "" &&
                      this.state.fechaAnfitrion != "" &&
                      this.state.linkAnfitrion != "" &&
                      this.state.nombreAnfitrion != "" && (
                        <div>
                          <Row>
                            <div className="card-header-mobile">
                              <Row>
                                <Col xs="auto">
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={this.imagenTipoRifa()}
                                    fixedWidth
                                    size="3x"
                                  />
                                </Col>
                                <Col xs="auto">
                                  <div className="header-info-mobile">
                                    <strong> Premio </strong>
                                    <Linkify
                                      properties={{
                                        target: "_blank",
                                        style: {
                                          color: "green",
                                          fontWeight: "bold",
                                        },
                                      }}
                                    >
                                      <div className="lead-aux-mobile">
                                        {this.state.premioAnfitrion}
                                      </div>
                                    </Linkify>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="card-header-mobile">
                              <Row>
                                <Col xs="auto">
                                  <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    fixedWidth
                                    size="3x"
                                  />
                                </Col>
                                <Col xs="auto">
                                  <div className="header-info-mobile">
                                    <strong> ¿Cuándo juega? </strong>
                                    <div className="lead-aux-mobile">
                                      {" "}
                                      {this.state.fechaAnfitrion
                                        .toISOString()
                                        .replace(/T/, " ")
                                        .replace(/\..+/, "")
                                        .substring(0, 10)}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {typeof this.state.rangoSorteo == "undefined" && (
                              <div className="card-header-mobile">
                                <Row>
                                  {" "}
                                  <Col xs="auto">
                                    <FontAwesomeIcon
                                      icon={faDollarSign}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info-mobile">
                                      <strong> Valor </strong>
                                      <div className="lead-aux-mobile">
                                        {this.state.valorBoleta}
                                      </div>
                                      {` ${
                                        this.state.currencyAux ??
                                        paises[this.state.pais]
                                      }`}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {typeof this.state.rangoSorteo == "undefined" && (
                              <div className="card-header-mobile">
                                <Row>
                                  <Col xs="auto">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faExternalLinkAlt}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info-mobile">
                                      <strong> Premio mayor de </strong>{" "}
                                      <u>
                                        <div className="lead-aux-mobile">
                                          {this.state.linkAnfitrion}
                                        </div>
                                      </u>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {typeof this.state.rangoSorteo != "undefined" && (
                              <div className="card-header-mobile">
                                <Row>
                                  <Col xs="auto">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faRandom}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info-mobile">
                                      <strong>
                                        {" "}
                                        El sorteo jugó de{" "}
                                        {this.state.rangoSorteo} con{" "}
                                        {this.state.conteosSorteo} intento(s) de
                                        lanzamiento de balotas.
                                      </strong>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {typeof this.state.ganador != "undefined" && (
                              <div className="card-header-mobile">
                                <Row>
                                  <Col xs="auto">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faAward}
                                      fixedWidth
                                      size="3x"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <div className="header-info-mobile">
                                      <strong> Ganador </strong>{" "}
                                      <u>
                                        <div className="lead-aux-mobile">
                                          El número
                                          {this.state.rifaTipoNum === 300 ||
                                          this.state.rifaTipoNum === 500 ||
                                          this.state.rifaTipoNum === 1000
                                            ? this.state.ganador < 100
                                              ? this.state.ganador < 10
                                                ? "00" +
                                                  this.state.ganador.toString()
                                                : "0" +
                                                  this.state.ganador.toString()
                                              : this.state.ganador
                                            : this.state.ganador < 10
                                            ? "0" +
                                              this.state.ganador.toString()
                                            : this.state.ganador}
                                        </div>
                                      </u>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            <div className="card-header-mobile">
                              <Row>
                                {" "}
                                <Col xs="auto">
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    fixedWidth
                                    size="3x"
                                  />
                                </Col>
                                <Col xs="auto">
                                  <div className="header-info-mobile">
                                    <strong> Responsable </strong>{" "}
                                    <div className="lead-aux-mobile">
                                      {this.state.nombreAnfitrion}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {this.state.nombreAnfitrion != "" &&
                              typeof this.state.ganador == "undefined" && (
                                <div>
                                  <Row>
                                    <Col
                                      xs={{ size: 6, offset: 1 }}
                                      md={{ size: 6, offset: 1 }}
                                      xl={{ size: 6, offset: 1 }}
                                    >
                                      <div className="lead-aux-2">
                                        ¡Crea tu propio talonario!
                                      </div>
                                    </Col>
                                    <Col
                                      xs={{ size: 4 }}
                                      md={{ size: 4 }}
                                      xl={{ size: 4 }}
                                    >
                                      <div>
                                          <Button
                                            styles={{
                                              backgroundColor:
                                                "var(--color-secundario)",
                                              color: "var(--color-backround)",
                                            }}
                                            rotate={true}
                                            tooltip="¡Instala la app!"
                                            onClick={()=>{confirmAlert({
                                              customUI: ({ onClose }) => {
                                               return (
                                                 <div className='card2' color= "var(--color-backround)" >
                                                   <InstallApp/>
                                                   <button className="boton-primario"
                                                     onClick={() => {
                                                       onClose();
                                                     }}
                                                   >
                                                     Entendido
                                                   </button>
                                                 </div>
                                               );
                                              }
                                              });}}
                                          >
                                            <FontAwesomeIcon
                                              icon={faDownload}
                                              size="2x"
                                            />
                                          </Button>
                                      </div>
                                    </Col>
                                  </Row>{" "}
                                </div>
                              )}
                          </Row>
                        </div>
                      )}

                    {this.state.fadeln && (
                      <ul>
                        <Fade
                          in={this.state.fadeln}
                          tag="h2"
                          className="mt-2 text-center text-sucess"
                        >
                          {this.state.message}
                        </Fade>
                      </ul>
                    )}
                    {!this.state.habilitarSorteo &&
                      this.state.fechaActual.toString().substring(0, 15) ===
                        this.state.fechaAnfitrion
                          .toString()
                          .substring(0, 15) && (
                        <h4>
                          Recuerda que esta rifa/concurso estará en curso hoy.
                        </h4>
                      )}
                    {(!this.state.habilitarSorteo &&
                      this.state.spinnerInput == 1 &&
                      this.state.fechaActual >=
                        this.state.fechaAnfitrionLast) ||
                      (!this.state.habilitarSorteo &&
                        this.state.existeRifa &&
                        this.state.fechaActual >=
                          this.state.fechaAnfitrionLast && (
                          <h4>
                            La rifa/concurso ya finalizó, no es posible adquirir
                            más boletas.
                          </h4>
                        ))}
                  </nav>
                </MobileView>
              </div>
            }
          >
            <section>
              {this.state.spinnerInput == 1 &&
                this.state.fechaActual >= this.state.fechaAnfitrion && <br />}
              {(this.state.spinnerInput == 1 &&
                this.state.fechaActual >= this.state.fechaAnfitrion) ||
                (this.state.existeRifa &&
                  this.state.fechaActual >= this.state.fechaAnfitrion && (
                    <div>
                      <br />
                      <br />
                    </div>
                  ))}
              <BrowserView>
                {this.state.habilitarModo && (
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
                {!this.state.habilitarModo && this.state.rangoSorteo != "" && (
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
                {this.state.habilitarModo &&
                  typeof this.state.ganador != "undefined" && (
                    <div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  )}
                {!this.state.habilitarModo &&
                  this.state.nombreAnfitrion == "" && (
                    <div>
                      <br />
                      <img
                        src={this.state.imagenLogo}
                        alt="talonario"
                        className="photo-g"
                      />
                    </div>
                  )}
              </BrowserView>
              <MobileView>
                <br />
                <br />
                <br />
                {!this.state.habilitarModo && this.state.rangoSorteo != "" && (
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
                {this.state.habilitarModo && (
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
                {!this.state.habilitarModo &&
                  this.state.nombreAnfitrion == "" && (
                    <div>
                      <br />
                      <img
                        src={this.state.imagenLogo}
                        alt="talonario"
                        className="photo-g"
                      />
                    </div>
                  )}

                <br />
                <br />
                <br />
              </MobileView>
            </section>
          </StickyHeader>
        )}

        {this.state.codigoRifa && (
          <>
            <div className="sticky-switch-details">
              <Button
                onClick={() => {
                  this.setState({
                    showDetails: !this.state.showDetails,
                  });
                }}
                styles={{
                  marginBottom: "15px",
                  backgroundColor: "var(--color-secundario)",
                  color: "var(--color-backround)",
                }}
              >
                <FontAwesomeIcon
                  icon={this.state.showDetails ? faCompress : faExpand}
                  size="2x"
                />
              </Button>
              {this.state.showDetails
                ? "Esconder detalles"
                : "Expandir detalles"}
            </div>
            <div className="sticky-instructions">
              <div className="into-menu-bar">
                <a
                  href="https://youtu.be/yVMCBMUz8o4"
                  target="_blank"
                  rel="external"
                  className="option2"
                >
                  <FontAwesomeIcon icon={faPlay} size="2x" />
                  <Button
                    styles={{
                      backgroundColor: "var(--color-backround)",
                      color: "var(--color-primario)",
                    }}
                  >
                    Tutorial
                  </Button>
                </a>
              </div>
            </div>
          </>
        )}

        <header className="App-header">
          <br />
          <Container>
            <Row>
              <Col>
                {this.state.subscrito && this.state.slogan ? (
                  <h1 className="tituloInicial">{this.state.slogan} </h1>
                ) : (
                  <h1 className="tituloInicial">
                    {" "}
                    Crea, gestiona, personaliza y comparte tu talonario virtual
                    con RifaYa{" "}
                  </h1>
                )}
              </Col>
            </Row>
            {!this.state.habilitarModo && this.state.nombreAnfitrion == "" && (
              <Row>
                <Col>
                  <h2 className="tituloInicial">
                    {" "}
                    Descarga nuestra App oficial y no vuelvas a usar
                    talonarios de papel.{" "}
                  </h2>
                </Col>
                <div
                  className="sticky-instructions parpadea"
                  style={{ left: "65%", top: "70%" }}
                >
                   <p style={{
                    color: 'var(--color-secundario)'
                   }}>
                    ¡Crea tu propio talonario!
                   </p>
                   
                  <Button
                                styles={{
                                  backgroundColor: "var(--color-secundario)",
                                  color: "var(--color-backround)",
                                }}
                                rotate={true}
                                onClick={()=>{
                                  confirmAlert({
                                  customUI: ({ onClose }) => {
                                   return (
                                     <div className='card2' color= "var(--color-backround)" >
                                       <InstallApp/>
                                       <button className="boton-primario"
                                         onClick={() => {
                                           onClose();
                                         }}
                                       >
                                         Entendido
                                       </button>
                                     </div>
                                   );
                                  }
                                  });}}
                              >           
                              <FontAwesomeIcon
                                  icon={faDownload}
                                  size="2x"
                                />                    
                              </Button>
                </div>
              </Row>
            )}

            <Row>
              <BrowserView>
                <Col
                  sm={{ size: 2, offset: 0 }}
                  md={{ size: 2, offset: 0 }}
                  xl={{ size: 2, offset: 0 }}
                >
                  {this.state.nombreAnfitrion != "" && (
                    <div>
                      {this.state.imagenAnuncioC && this.state.anunciosWeb && (
                        <VideoAnuncio
                          onClick={() => this.clickAnuncioV()}
                          imagenCargada={this.funcionImagenCargada}
                          linkAnuncio={this.state.linkAnuncioV}
                          imagenAnuncio={this.state.imagenAnuncioC}
                          entrada="imagen-ac"
                        />
                      )}
                    </div>
                  )}
                  {this.state.fotoPremio && (
                    <div>
                      <Row>
                        <Col>
                          <span className="lead6">Imagen representativa: </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/rifas/" +
                              this.state.fotoPremio
                            }
                            alt="imagen representativa"
                            className="imagen-premio"
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </BrowserView>

              <Col md={{ size: 5, offset: 2 }} xl={{ size: 5, offset: 0 }}>
                <Table>
                  <thead>
                    {(this.state.nombreAnfitrion != "" ||
                      this.state.cargandoApp) && (
                      <tr className="lead6">
                        <th colSpan="2">{this.state.pasoDescripción}</th>
                      </tr>
                    )}
                  </thead>
                  {this.state.nombreAnfitrion != "" && (
                    <tbody>
                      <tr>
                        {typeof this.state.ganador != "undefined" && (
                          <td>
                              <Button
                                styles={{
                                  backgroundColor: "var(--color-secundario)",
                                  color: "var(--color-backround)",
                                }}
                                rotate={true}
                                tooltip="¡Instala la app!"
                                onClick={()=>{confirmAlert({
                                  customUI: ({ onClose }) => {
                                   return (
                                     <div className='card2' color= "var(--color-backround)" >
                                       <InstallApp/>
                                       <button className="boton-primario"
                                         onClick={() => {
                                           onClose();
                                         }}
                                       >
                                         Entendido
                                       </button>
                                     </div>
                                   );
                                  }
                                  });}}
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  size="2x"
                                />
                              </Button>
                            <div
                              className="lead-aux-2"
                              style={{ color: "var(--color-backround)" }}
                            >
                              ¡Crea tu propio talonario!
                            </div>
                          </td>
                        )}
                        {typeof this.state.rangoSorteo == "undefined" &&
                          this.state.estadoRifa == 1 &&
                          this.state.habilitarModo &&
                          this.state.fechaActual <
                            this.state.fechaAnfitrionLast && (
                            <td>
                              <button
                                className="boton-eleccion"
                                onClick={() => {
                                  this.cargandoRefM.current.textContent = " ";
                                  this.cargandoRefM.current.classList.add(
                                    `loader`
                                  );
                                  setTimeout(() => {
                                    this.handleAdquerirBoletas();
                                  }, 1);
                                }}
                              >
                                ¡ADQUIRIR BOLETAS EN ESTE TALONARIO!
                              </button>
                            </td>
                          )}

                        {typeof this.state.rangoSorteo == "undefined" &&
                          this.state.habilitarModo && (
                            <td>
                              <button
                                className="boton-eleccion"
                                onClick={this.handleVerBoletas}
                              >
                                ¡VER TUS BOLETAS EN ESTE TALONARIO!
                              </button>
                            </td>
                          )}
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Col>

              <MobileView>
                <Col md={{ size: 2, offset: 3 }} xl={{ size: 3, offset: 3 }}>
                  {this.state.cargandoApp && (
                    <ReactLoading
                      type={"spin"}
                      color={"var(--color-backround)"}
                      height={100}
                      width={100}
                    />
                  )}
                </Col>
              </MobileView>
            </Row>
            {/*this.state.anunciosWeb && <div id="amzn-assoc-ad-4ad2864e-a14d-47c0-91ee-e1e73b6b6350"> </div>*/}
            {this.state.habilitarModo && (
              <Row>
                <Col
                  md={{ size: 6, offset: 3 }}
                  xs={{ size: 6, offset: 3 }}
                  xl={{ size: 6, offset: 3 }}
                >
                  <Button
                    onClick={() => {
                      this.setState({
                        showInfoDetalle: !this.state.showInfoDetalle,
                      });
                    }}
                    styles={{
                      marginBottom: "15px",
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  </Button>
                </Col>
              </Row>
            )}

            {this.state.habilitarModo && (
              <Row>
                <Col
                  md={{ size: 6, offset: 3 }}
                  xs={{ size: 6, offset: 3 }}
                  xl={{ size: 6, offset: 3 }}
                >
                  <Collapse isOpen={this.state.showInfoDetalle}>
                    <Linkify
                      properties={{
                        target: "_blank",
                        style: { color: "green", fontWeight: "bold" },
                      }}
                    >
                      <h4 className="mensajePersonalizado">
                        {this.state.infoDetalle}
                      </h4>
                    </Linkify>
                  </Collapse>
                </Col>
              </Row>
            )}

            {this.state.rangoSorteo != undefined && (
              <Row>
                <br />
              </Row>
            )}
            <div ref={this.cargandoRefM}> </div>

            {this.state.nombreAnfitrion != "" &&
              this.state.spinnerInput > 0 && (
                <MobileView>
                  {this.state.imagenAnuncioC && this.state.anunciosWeb && (
                    <VideoAnuncio
                      onClick={() => this.clickAnuncioV()}
                      imagenCargada={this.funcionImagenCargada}
                      linkAnuncio={this.state.linkAnuncioV}
                      imagenAnuncio={this.state.imagenAnuncioC}
                      entrada="imagen-ac"
                    />
                  )}
                </MobileView>
              )}
            <MobileView>
              {" "}
              {this.state.fotoPremio && (
                <div>
                  <Row>
                    <Col>
                      <span className="lead6">Imagen representativa: </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/rifas/" +
                          this.state.fotoPremio
                        }
                        alt="imagen representativa"
                        className="imagen-premio"
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </MobileView>
          </Container>
        </header>

        {/*this.state.anunciosWeb && <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ez&f=ifr&linkID=aed614f3ad8359f1b7d776fa86d8a83f&t=bifrostapps-20&tracking_id=bifrostapps-20" marginWidth="0" style={{border:"none", width:"728px", height:"90px", scrolling:"no" }} frameBorder="0"></iframe>*/}

        <div className="container-fluid" id="contenedor">
          <div className="row mt-15">
            <div className="col-xs-6 col-md-2">
              {this.state.spinnerInput > 0 &&
                typeof this.state.rangoSorteo == "undefined" && (
                  <div>
                    {this.state.imagenAnuncioV1 &&
                      this.state.anunciosWeb /*<Container><Row>
                      <Col><iframe style={{width:"120px", height:"240px", marginwidth:"0", marginheight:"0", scrolling:"no", frameborder:"0"}} src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=bifrostapps-20&marketplace=amazon&amp;region=US&placement=B095WMTPL9&asins=B095WMTPL9&linkId=5da9f6bae73939ac5b166766b2bdf3ea&show_border=false&link_opens_in_new_window=true&price_color=b33279&title_color=bf6600&bg_color=a7bdd1"></iframe>
                    </Col>
                      </Row>
                      <Row>
                      <Col>
                        <iframe style={{width:"120px", height:"240px", marginwidth:"0", marginheight:"0", scrolling:"no", frameborder:"0"}} src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=bifrostapps-20&language=es_US&marketplace=amazon&region=US&placement=B07PJ67CKC&asins=B07PJ67CKC&linkId=5f4f0551417c37d6aa5e005463e22351&show_border=true&link_opens_in_new_window=true"></iframe>
                      </Col>
                    </Row>
                  </Container>*/ && (
                        <VideoAnuncio
                          onClick={() => this.clickAnuncioFV()}
                          imagenCargada={this.funcionImagenCargada}
                          linkAnuncio={this.state.linkAnuncioFV}
                          imagenAnuncio={this.state.imagenAnuncioV1}
                          entrada="imagen-av"
                        />
                      )}
                  </div>
                )}
            </div>
            <div className="col-xs-12 col-md-8">
              {typeof this.state.rangoSorteo == "undefined" &&
                this.state.adquirirNueva == false &&
                this.state.spinnerInput == 1 &&
                this.state.fechaActual < this.state.fechaAnfitrionLast && (
                  <div className="card">
                    <button
                      className="boton-primario-2"
                      onClick={() => {
                        this.cargandoRefM.current.textContent = " ";
                        this.cargandoRefM.current.classList.add(`loader`);
                        setTimeout(() => {
                          this.cargandoRefM.current.textContent = " ";
                          this.cargandoRefM.current.classList.remove(`loader`);
                        }, 4000);
                        setTimeout(() => {
                          this.setState({ adquirirNueva: true });
                        }, 1);
                      }}
                    >
                      Presionar para adquirir otra boleta
                    </button>
                  </div>
                )}
              {typeof this.state.rangoSorteo == "undefined" &&
                this.state.adquirirNueva == true &&
                this.state.spinnerInput == 1 &&
                this.state.fechaActual < this.state.fechaAnfitrionLast && (
                  <FormularioAdquirir
                    onAddTodo={this.handleAddDatosAdquirir}
                    codigoData={this.state.codigoInput}
                    estadoData={this.state.estadoRifa}
                    datosBoletas={this.state.arrayBoletas}
                    boletasNum={this.state.rifaTipoNum}
                  />
                )}
              {typeof this.state.rangoSorteo == "undefined" &&
                this.state.spinnerInput == 2 && (
                  <FormularioVer
                    onAddTodo={this.handleAddDatosVer}
                    codigoData={this.state.codigoInput}
                  />
                )}
            </div>
          </div>

          <Row>{this.state.verBoletasEnable && boletas}</Row>
          {this.state.nombreAnfitrion != "" && <br />}

          <Row>
            <Col xs="4" md="4" xl="4">
              {this.state.spinnerInput == 0 && (
                <MobileView>
                  {this.state.imagenAnuncioV1 && this.state.anunciosWeb && (
                    <VideoAnuncio
                      onClick={() => this.clickAnuncioFV()}
                      imagenCargada={this.funcionImagenCargada}
                      linkAnuncio={this.state.linkAnuncioFV}
                      imagenAnuncio={this.state.imagenAnuncioV2}
                      entrada="imagen-av-m"
                    />
                  )}
                </MobileView>
              )}
            </Col>
            <Col xs="4" md="4" xl="4">
              {this.state.nombreAnfitrion != "" &&
                this.state.spinnerInput == 0 && (
                  <MobileView>
                    <div style={{ color: "var(--color-primario)" }}>
                      Talonario creado en:
                    </div>
                    <a
                      className="App-link"
                      href="https://play.google.com/store/apps/details?id=com.bifrost.rifaya"
                      target="_blank"
                      rel="external"
                    >
                      <img
                        src={this.state.imagenLogo}
                        alt="talonario"
                        className="photo-p"
                      />
                    </a>
                  </MobileView>
                )}
            </Col>
            <Col xs="4" md="4" xl="4">
              {this.state.spinnerInput == 0 && (
                <MobileView>
                  {this.state.imagenAnuncioV1 && this.state.anunciosWeb && (
                    <VideoAnuncio
                      onClick={() => this.clickAnuncioFV()}
                      imagenCargada={this.funcionImagenCargada}
                      linkAnuncio={this.state.linkAnuncioFV}
                      imagenAnuncio={this.state.imagenAnuncioV1}
                      entrada="imagen-av-m"
                    />
                  )}
                </MobileView>
              )}
            </Col>
            <BrowserView>
              <Col
                xs={{ offset: 3, size: 6 }}
                md={{ offset: 3, size: 6 }}
                xl={{ offset: 3, size: 6 }}
              >
                {
                  this.state.anunciosWeb && (
                    <VideoAnuncio
                      onClick={() => this.clickAnuncioFH()}
                      imagenCargada={this.funcionImagenCargada}
                      linkAnuncio={this.state.linkAnuncioFH}
                      imagenAnuncio={this.state.imagenAnuncioH}
                      entrada="imagen-ah"
                    />
                  ) /*<div id="amzn-assoc-ad-4ad2864e-a14d-47c0-91ee-e1e73b6b6350"> </div>*/
                }
              </Col>
            </BrowserView>

            {this.state.nombreAnfitrion != "" &&
              this.state.spinnerInput > 0 && (
                <MobileView>
                  <Col
                    xs={{ offset: 4, size: 6 }}
                    md={{ offset: 4, size: 6 }}
                    xl={{ offset: 3 }}
                  >
                    {
                      this.state.anunciosWeb && (
                        <VideoAnuncio
                          onClick={() => this.clickAnuncioFH()}
                          imagenCargada={this.funcionImagenCargada}
                          linkAnuncio={this.state.linkAnuncioFH}
                          imagenAnuncio={this.state.imagenAnuncioH}
                          entrada="imagen-ah"
                        />
                      ) /*<div id="amzn-assoc-ad-4ad2864e-a14d-47c0-91ee-e1e73b6b6350"> </div>*/
                    }
                  </Col>
                </MobileView>
              )}
          </Row>

          {this.state.habilitarModo && this.state.anunciosWeb && <br />}
          {this.state.habilitarModo && this.state.anunciosWeb && <br />}
          {this.state.habilitarModo && this.state.anunciosWeb && <br />}
          {this.state.habilitarModo && this.state.anunciosWeb && <br />}
          {this.state.habilitarModo && this.state.anunciosWeb && <br />}
          {this.state.habilitarModo && <br />}

          {/*this.state.anunciosWeb && <ins
                                     className="adsbygoogle"
                                     data-ad-client="ca-pub-7028770734269559"
                                     data-ad-slot="1357790805"
                                     data-ad-format="auto"
                                     data-full-width-responsive="true"
                                     data-adtest="on"
                                     style={{ display:"block" }}
                                   />*/}
        </div>
        {this.state.verBoletasEnable && (
          <BrowserView>
            {this.state.imagenAnuncioE && this.state.anunciosWeb && (
              <VideoAnuncio
                onClick={() => this.clickAnuncioV()}
                imagenCargada={this.funcionImagenCargada}
                linkAnuncio={this.state.linkAnuncioV}
                imagenAnuncio={this.state.imagenAnuncioE}
                entrada="sticky-imagen"
              />
            )}
          </BrowserView>
        )}

        <div className="bg-docs-transparent-grid rounded sticky-cookies">
          <UncontrolledAlert
            style={{marginTop: "10vh", backgroundColor: "var(--color-claro)" }}
            fade={false}
          >
            <Toast style={{ fontSize: "10px" }}>
              <ToastHeader>Política sobre cookies</ToastHeader>
              <ToastBody>
                Utilizamos cookies propias y de terceros para ofrecerte una
                mejor experiencias y servicio, en base a tus habitos de
                navegación. Si continuas navegando, consideramos que aceptas su
                uso. Puedes obtener más información en
                <div
                  onClick={() => {
                    confirmAlert({
                      customUI: ({ onClose }) => {
                        return (
                          <div className="card2" color="var(--color-backround)">
                            <Cookies />
                            <button
                              className="boton-primario"
                              onClick={() => {
                                onClose();
                              }}
                            >
                              Entendido
                            </button>
                          </div>
                        );
                      },
                    });
                  }}
                >
                  <a href={"#codigo=" + this.state.codigoInput}>
                    nuestra Política de Cookies.
                  </a>
                </div>
              </ToastBody>
            </Toast>
          </UncontrolledAlert>
        </div>

        <div className="sticky-boton">
          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.redes[0] &&
            this.state.redes[0] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[0]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faYoutube} size="2x" />
                  </Button>
                </a>
              </Col>
            )}
          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.redes[1] &&
            this.state.redes[1] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[1]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                  </Button>
                </a>
              </Col>
            )}
          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.redes[2] &&
            this.state.redes[2] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[2]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                  </Button>
                </a>
              </Col>
            )}

          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.redes[3] &&
            this.state.redes[3] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[3]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                  </Button>
                </a>
              </Col>
            )}
          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.redes[4] &&
            this.state.redes[4] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[4]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faTiktok} size="2x" />
                  </Button>
                </a>
              </Col>
            )}
          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.subscrito &&
            this.state.redes[5] &&
            this.state.redes[5] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[5]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faGlobe} size="2x" />
                  </Button>
                </a>
              </Col>
            )}
          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.subscrito &&
            this.state.redes[6] &&
            this.state.redes[6] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[6]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faPhone} size="2x" />
                  </Button>
                </a>
              </Col>
            )}
          {this.state.redes &&
            this.state.codigoRifa &&
            this.state.subscrito &&
            this.state.redes[7] &&
            this.state.redes[7] !== "" && (
              <Col>
                <a
                  className="App-link"
                  href={this.state.redes[7]}
                  target="_blank"
                  rel="external"
                >
                  <Button
                    styles={{
                      backgroundColor: "var(--color-secundario)",
                      color: "var(--color-backround)",
                    }}
                    rotate={true}
                  >
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                  </Button>
                </a>
              </Col>
            )}
        </div>

        <div className="fixed-bottom">
          {!this.state.cargandoApp && (
            <ScrollProgressRead
              backgroundColor="var(--color-secundario)"
              barColor="var(--color-primario)"
              height="15px"
            />
          )}
        </div>
        <Navbar color="dark" dark>
          <Container>
            <NavbarBrand>
              {!this.state.codigoRifa && (
                <div style={{ marginBottom: "20px" }}>
                  <Row>
                    <Col sm="12" md="12" xl="12">
                      <span
                        style={{
                          color: "var(--color-secundario)",
                          fontSize: "20px",
                        }}
                      >
                        {!this.state.marcaRifa &&
                          "Aprende a usar RifaYA y únete a la"}{" "}
                        Comunidad:
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <a
                        className="App-link"
                        href="https://www.youtube.com/channel/UCLLbx3wP1tliuFGScABWwKQ"
                        target="_blank"
                        rel="external"
                      >
                        <Button
                          styles={{
                            backgroundColor: "var(--color-secundario)",
                            color: "var(--color-backround)",
                          }}
                          rotate={true}
                          tooltip="¡Únete al canal!"
                        >
                          <FontAwesomeIcon icon={faYoutube} size="2x" />
                        </Button>
                      </a>
                    </Col>
                    <Col>
                      <Button
                        styles={{
                          backgroundColor: "var(--color-secundario)",
                          color: "var(--color-backround)",
                        }}
                        rotate={true}
                        onClick={() => {
                          window.open("mailto:soporte@rifaya-app.online");
                        }}
                        tooltip="Contacta a soporte"
                      >
                        <FontAwesomeIcon icon={faAt} size="2x" />
                      </Button>
                    </Col>

                    <Col>
                      <a
                        className="App-link"
                        href="https://instagram.com/rifaya.app?igshid=1585cp8xhf31"
                        target="_blank"
                        rel="external"
                      >
                        <Button
                          styles={{
                            backgroundColor: "var(--color-secundario)",
                            color: "var(--color-backround)",
                          }}
                          rotate={true}
                          tooltip="¡Únete a la comunidad!!"
                        >
                          <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </Button>
                      </a>
                    </Col>

                    <Col>
                      <a
                        className="App-link"
                        href="https://www.facebook.com/RifaYa-110752797449184"
                        target="_blank"
                        rel="external"
                      >
                        <Button
                          styles={{
                            backgroundColor: "var(--color-secundario)",
                            color: "var(--color-backround)",
                          }}
                          rotate={true}
                          tooltip="¡Únete a la comunidad!"
                        >
                          <FontAwesomeIcon icon={faFacebook} size="2x" />
                        </Button>
                      </a>
                    </Col>
                    <Col>
                      <a
                        className="App-link"
                        href="https://vm.tiktok.com/ZMeQURRsm/"
                        target="_blank"
                        rel="external"
                      >
                        <Button
                          styles={{
                            backgroundColor: "var(--color-secundario)",
                            color: "var(--color-backround)",
                          }}
                          rotate={true}
                          tooltip="¡Síguenos!"
                        >
                          <FontAwesomeIcon icon={faTiktok} size="2x" />
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </div>
              )}

              <Row>
                <Col xs="auto">
                  <div></div>
                </Col>
                <Col xs="auto">
                  <div
                    className="into-terminos-bar"
                    onClick={() => {
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <div
                              className="card2"
                              color="var(--color-backround)"
                            >
                              <Terminos />
                              <button
                                className="boton-primario"
                                onClick={() => {
                                  onClose();
                                }}
                              >
                                Entendido
                              </button>
                            </div>
                          );
                        },
                      });
                    }}
                  >
                    <a
                      href={"#codigo=" + this.state.codigoInput}
                      className="terminos1"
                    >
                      <li style={{ textAlign: "left" }}>
                        Terminos y condiciones / políticas de privacidad
                      </li>
                    </a>
                  </div>
                </Col>
                <BrowserView>
                  {this.state.cargandoApp && (
                    <Col xs="auto">
                      <ReactLoading
                        type={"spin"}
                        color={"var(--color-secundario)"}
                        height={40}
                        width={40}
                      />
                    </Col>
                  )}
                </BrowserView>
              </Row>
            </NavbarBrand>
          </Container>
        </Navbar>

        <Row>
          <Col xs="5"></Col>
          <Col xs="auto">
            <div>
              <a
                href="https://bifrost-apps.com/"
                className="card-header"
                target="_blank"
                rel="author"
              >
                Aplicativo web/móvil desarrollado por{"   "}
                <img
                  src="https://bifrost-apps.com/logoBifrost.png"
                  width={100}
                />
              </a>
            </div>
          </Col>
        </Row>
        <br />
      </div>
    );
  }
}
export default App;
