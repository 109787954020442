import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAI,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    appId: process.env.REACT_APP_ONE_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  }
);

let db=firebase.firestore();
//let fb=firebase;


export default db;
export const stg = firebase.storage();
