import { Row, Col } from "reactstrap";
import { Button } from "react-floating-action-button";
import {
    faGooglePlay, faAppStoreIos
  } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function InstallApp(props){
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem'
    }}>
<Row>
        <Col xs="auto">
                    <p style={{
                                color: 'var(--color-claro)'
                            }}>
                    RifaYa Full:
                   </p>
            <a
                className="App-link"
                href="https://play.google.com/store/apps/details?id=com.bifrost.rifaya"
                target="_blank"
                rel="external"
            >
            <Button styles={{backgroundColor: "var(--color-secundario)", color: "var(--color-backround)"}} rotate={true} tooltip="¡Descarga RifaYa en Google Play!"><FontAwesomeIcon icon={faGooglePlay} size="2x" /></Button>
            </a>           
        </Col>
        <Col xs="auto">
                    <p style={{
                                            color: 'var(--color-claro)'
                                        }}>
                    RifaYa Lite:
                   </p>
            <a
                className="App-link"
                href="https://apps.apple.com/us/app/rifaya-lite/id6478363977"
                target="_blank"
                rel="external"
            >
            <Button styles={{backgroundColor: "var(--color-secundario)", color: "var(--color-backround)"}} rotate={true} tooltip="¡Descarga RifaYa en la App Store!"><FontAwesomeIcon icon={faAppStoreIos} size="2x" /></Button>
            </a>           
        </Col>
        <Col xs="auto">
        <p style={{
                                            color: 'var(--color-claro)'
                                        }}>
                    RifaYa Lite:
                   </p>
            <a
                className="App-link"
                href="https://play.google.com/store/apps/details?id=com.bifrost.rifayalite.rifaya_lite"
                target="_blank"
                rel="external"
            >
            <Button styles={{backgroundColor: "var(--color-secundario)", color: "var(--color-backround)"}} rotate={true} tooltip="¡Descarga RifaYa Lite en Google Play!"><FontAwesomeIcon icon={faGooglePlay} size="2x" /></Button>
            </a>           
        </Col>
    </Row>
    </div>  
    
    
}