import React, { Component }  from 'react';
import ReactSelect from 'react';
import {Container} from 'reactstrap';
import '../App.css';
import db from '../FirestoreConfig';
import Recaptcha from 'react-recaptcha';


export default class FormularioVer extends Component{
state={
    celular: '',
    codigo: this.props.codigoData,
    isVerificado1:false
}

componentWillReceiveProps(prevProps) {
  // only update chart if the data has changed
    //console.log("dato anterior: ", prevProps.codigoData, "dato nuevo: ", this.props.codigoData)
    this.setState({
      codigo: prevProps.codigoData,
    })
  }


  recaptchaVerificador1=(response)=>{
    //console.log("resultado: ",response)
    if (response){
      this.setState({
        isVerificado1:true
      })
    }
    //console.log("verificado!", this.state.isVerificado)
  }
  recaptchaCargador1=()=>{
    //console.log("se ha cargado adecuadamente el recaptcha para ver un usuario")
  }


handleSubmit=(e)=> {
    e.preventDefault();
    this.props.onAddTodo(this.state);
    this.setState({
      isVerificado1:false
    })
  }

  handleInputChange=(e)=> {
    const {value, name} = e.target;
    //console.log(value, name);
    if (value=="" | value==" "){
    }else{
      this.setState({
        [name]: value
      })
    }
  }

  render() {
    return (
      <div className="card">
        <form onSubmit={this.handleSubmit} className="card-body">
            <p className="lead-6"><b>Ingresa el número de teléfono que registraste: </b></p>
          <div className="form-group">
          <input
            type="tel"
            name="celular"
            pattern="[0-9]*"
            className="form-control"
            defaultValue=""
            onChange={this.handleInputChange}
            placeholder="Número de teléfono"
            />
          </div>
          {(!this.state.isVerificado1) && <div><p className="lead-6"><b>Para continuar verifica la casilla: </b></p>
          <Recaptcha
            sitekey="6Lci89YZAAAAAPiQn7YFoie-yUx6339BueUScWbL"
            render="explicit"
            hl="es"
            onloadCallback={this.recaptchaCargador1}
            verifyCallback={this.recaptchaVerificador1}
          /></div>}
          {(this.state.isVerificado1) &&
          <button type="submit" className="boton-primario">
              Presionar para acceder
          </button>}
        </form>
      </div>

    )
  }


}
