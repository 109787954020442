import React from 'react';
import { Tooltip, Container, Row, Col} from 'reactstrap';

function VideoAnuncio(props){
  const [cambio, setCambio] = React.useState(false);
  const [formato, setFormato] = React.useState(true);

  const toggle = () => setCambio(!cambio);

  const formatoImagen=""

  const alError = () => setFormato(false);

  const imagenAnuncio = props.imagenAnuncio



  return (
    <div key={imagenAnuncio} >
    <Container>
    <Row>
    <Col sm="12" md="12" xl="12">
      <span style={{color:"#f40552", fontSize:"10px"}} id="TooltipExample">RifaYa anuncios</span>
    </Col>
    </Row>
    <Row>
    <Col>
    <a href={props.linkAnuncio} onClick={() => props.onClick()} target="_blank" >
      {props.entrada==="imagen-ac"  && <img src={ imagenAnuncio} onLoad={props.imagenCargada} alt="Anuncios RifaYa talonario virtual" className="imagen-ac"/>}
      {props.entrada==="sticky-imagen"  && <img src={ imagenAnuncio} onLoad={props.imagenCargada} alt="Anuncios RifaYa talonario virtual" className="sticky-imagen"/>}
      {props.entrada==="imagen-ag"  && <img src={ imagenAnuncio} onLoad={props.imagenCargada} alt="Anuncios RifaYa talonario virtual" className="imagen-ag"/>}
      {props.entrada==="imagen-av-m"  && <img onError={alError} src={ imagenAnuncio} onLoad={props.imagenCargada} alt="Anuncios RifaYa talonario virtual" className="imagen-av-m"/>}
      {props.entrada==="imagen-ah"  && <img onError={alError} src={ imagenAnuncio}  onLoad={props.imagenCargada} alt="Anuncios RifaYa talonario virtual" className="imagen-ah"/>}
      {props.entrada==="imagen-av"  && <img onError={alError} src={ imagenAnuncio} onLoad={props.imagenCargada} alt="Anuncios RifaYa talonario virtual" className="imagen-av"/>}
    </a>
      <Tooltip placement="top" isOpen={cambio} target="TooltipExample" toggle={toggle} delay={{ show: 0, hide: 3000 }}>
        <a href="https://wa.link/y8uf0w"  target="_blank" >¡Impulsa tu negocio aqui!</a>
      </Tooltip>
      </Col>
      </Row>
      </Container>
    </div>
  );
}

export default VideoAnuncio;
