import React, { Component }  from 'react';
import ReactSelect from 'react';
import {Container} from 'reactstrap';
import '../App.css';


export default function Terminos(props){
    return (
      <div className='scroll-terminos' style={{ overflowY: 'scroll',overflowX: 'scroll'}}>
      <ul>
      <p><strong>&iquest;QU&Eacute; ES UNA COOKIE?</strong></p>
      <p>Una&nbsp;<em>cookie</em>&nbsp;es un peque&ntilde;o fichero de texto que se almacena en su navegador cuando visita casi cualquier p&aacute;gina web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa p&aacute;gina. Las&nbsp;<em>cookies</em>&nbsp;suelen almacenar informaci&oacute;n de car&aacute;cter t&eacute;cnico, preferencias personales, personalizaci&oacute;n de contenidos, estad&iacute;sticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc. El objetivo de la&nbsp;<em>cookie</em>&nbsp;es adaptar el contenido de la web a su perfil y necesidades, sin&nbsp;<em>cookies</em>&nbsp;los servicios ofrecidos por cualquier p&aacute;gina se ver&iacute;an mermados notablemente.</p>
      <p><strong>&iquest;QU&Eacute; NO ES UNA COOKIE?</strong></p>
      <p>No es un virus, ni un troyano, ni un gusano, ni spam, ni spyware, ni abre ventanas pop-up.</p>
      <p><strong>&iquest;QU&Eacute; INFORMACI&Oacute;N ALMACENA UNA COOKIE?</strong></p>
      <p>Las cookies NO suelen almacenar informaci&oacute;n sensible sobre usted, como tarjetas de cr&eacute;dito o datos bancarios, fotograf&iacute;as, su n&uacute;mero de identificaci&oacute;n o informaci&oacute;n personal, etc. Los datos que guardan son de car&aacute;cter t&eacute;cnico, preferencias personales, personalizaci&oacute;n de contenidos, etc.</p>
      <p>El servidor web asocia la cookie a su navegador web. De hecho, si usted navega habitualmente con Internet Explorer y prueba a navegar por la misma web con Firefox o Chrome ver&aacute; que la web no se da cuenta que es usted la misma persona porque en realidad est&aacute; asociando al navegador, no a la persona.</p>
      <p><strong>&iquest;QU&Eacute; COOKIES UTILIZA ESTE SITIO WEB?</strong></p>
      <p>Este sitio web utiliza la siguiente&nbsp;<strong>cookie propia</strong>:</p>
      <ul>
      <li>Cookies de sesi&oacute;n, para garantizar que los usuarios que interactuen con los talonarios sean humanos y no aplicaciones automatizadas. De esta forma se combate el&nbsp;<em>spam</em>.</li>
      </ul>
      <p>Este sitio web utiliza la siguiente&nbsp;<strong>cookie de terceros</strong>:</p>
      <ul>
      <li>Google Analytics: Almacena&nbsp;<em>cookies</em>&nbsp;para poder elaborar estad&iacute;sticas sobre el tr&aacute;fico y volumen de visitas de esta web. Al utilizar este sitio web est&aacute; consintiendo el tratamiento de informaci&oacute;n acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deber&aacute; hacerlo comunicando directamente con Google.</li>
      </ul>
      <p><strong>&iquest;PUEDE DESACTIVAR O ELIMINAR LAS COOKIES?</strong></p>
      <p>En cualquier momento podr&aacute; ejercer su derecho de desactivaci&oacute;n o eliminaci&oacute;n de cookies de este sitio web. Estas acciones se realizan de forma diferente en funci&oacute;n del navegador que est&eacute; usando.</p>
      <p>Le proporcionamos informaci&oacute;n de cada navegador donde se le explica la modificaci&oacute;n de la configuraci&oacute;n para la desactivaci&oacute;n y eliminaci&oacute;n de las&nbsp;<em>cookies</em>.</p>
      <ul>
      <li><a href="https://support.google.com/chrome/answer/95647?hl=es">Chrome</a></li>
      <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">Firefox</a></li>
      <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">Explorer</a></li>
      <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/12.0/mac/10.14">Safari</a></li>
      </ul>
      <p><strong>NOTAS ADICIONALES</strong></p>
      <ul>
      <li>Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las pol&iacute;ticas de privacidad que puedan tener los terceros mencionados en esta pol&iacute;tica de&nbsp;<em>cookies</em>.</li>
      <li>Los navegadores web son las herramientas encargadas de almacenar las&nbsp;<em>cookies</em>&nbsp;y desde este lugar debe efectuar su derecho a eliminaci&oacute;n o desactivaci&oacute;n de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulaci&oacute;n de las&nbsp;<em>cookies</em>&nbsp;por parte de los mencionados navegadores.</li>
      <li>En algunos casos es necesario instalar&nbsp;<em>cookies</em>&nbsp;para que el navegador no olvide su decisi&oacute;n de no aceptaci&oacute;n de las mismas.</li>
      <li>Para cualquier duda o consulta acerca de esta pol&iacute;tica de&nbsp;<em>cookies</em>&nbsp;no dude en comunicarse con nosotros a trav&eacute;s del bot&ograve;n de soporte que se encuentra hacia el final de la p&aacute;gina.</li>
      </ul>
      </ul>
      </div>
    )
}
