import React, { useState, useEffect, useRef } from "react";
import ReactSelect from "react";
import { Container, Row, Col, Tooltip } from "reactstrap";
import "../App.css";
import db from "../FirestoreConfig";
import Recaptcha from "react-recaptcha";
import ReactLoading from "react-loading";

var celularParti = "";
var nombreParti = "";
var confirmCelularParti = "";
var correoParti = "";

export default function FormularioAdquirir(props) {
  const codigo = props.codigoData;
  const estadoRifa = props.estadoData;
  const [rifaDatos, setRifaDatos] = useState(props.datosBoletas);
  const [spinner, setSpinner] = useState("");
  const [habilitarIngresoDatos, setHabilitarIngresoDatos] = useState(false);
  const [isVerificado1, setIsVerificado1] = useState(false);
  const [eleccionRandom, setEleccionRandom] = useState("");
  const [toogleEnable, setToogleEnable] = useState(false);
  const cargandoRef = useRef(" ");

  useEffect(() => {
    //console.log(props.datosBoletas)
    if (props.datosBoletas != rifaDatos) {
      //console.log("diferente")
      setRifaDatos(props.datosBoletas);
    }
    const valor = document.getElementById("boletasNum").value;
    //console.log("eleccion: "+eleccionRandom+" "+valor)
    if (eleccionRandom != valor) {
      cargandoRef.current.textContent = " ";
      cargandoRef.current.classList.add(`loader`);
      setTimeout(() => {
        cargandoRef.current.textContent = " ";
        cargandoRef.current.classList.remove(`loader`);
      }, 7000);
    }
    //cargandoRef.current.textContent = " ok "
  });

  const toggleTooltip = () => {
    setToogleEnable(!toogleEnable);
  };

  const handleRandomNum = () => {
    let maxR = 0;
    let arrayOpciones = [];
    //console.log("total: ",Object.keys(this.state.rifaDatos).length)
    let numRandom = 0;
    let randCoef = 0.0;
    if (43 == Object.keys(rifaDatos).length) {
      Object.values(rifaDatos).map((x, i) => {
        if (
          Object.values(serachIndex(i + 1))[
            Object.keys(serachIndex(i + 1)).findIndex((el) => el === "estado")
          ] != "-" &&
          Object.values(serachIndex(i + 1))[
            Object.keys(serachIndex(i + 1)).findIndex((el) => el === "estado")
          ] != "--"
        ) {
          arrayOpciones.push(
            Object.values(serachIndex(i + 1))[
              Object.keys(serachIndex(i + 1)).findIndex((el) => el === "estado")
            ]
          );
        }
      });
      maxR = arrayOpciones.length;
      //console.log("array: ",arrayOpciones)
      //console.log("max: ", maxR)
      do {
        randCoef = Math.random();
        //console.log("random: ", randCoef)
        numRandom = arrayOpciones[Math.floor(randCoef * maxR)];
      } while (numRandom == "undefined");
    } else {
      Object.values(rifaDatos).map((x, i) => {
        if (
          Object.values(serachIndex(i))[
            Object.keys(serachIndex(i)).findIndex((el) => el === "estado")
          ] != "-" &&
          Object.values(serachIndex(i))[
            Object.keys(serachIndex(i)).findIndex((el) => el === "estado")
          ] != "--"
        ) {
          arrayOpciones.push(
            Object.values(serachIndex(i))[
              Object.keys(serachIndex(i)).findIndex((el) => el === "estado")
            ]
          );
        }
      });
      maxR = arrayOpciones.length;
      numRandom = arrayOpciones[Math.floor(Math.random() * maxR)];
    }
    //console.log("eleccion: ",numRandom)
    if (numRandom == null) {
      alert("¡Todas las boletas ya han sido adquiridas!");
    } else {
      //console.log("calculando...")
      setEleccionRandom(numRandom);
      setHabilitarIngresoDatos(true);
      setSpinner(numRandom);
      //console.log("calculando!")
      window.scrollTo(0, document.getElementById("contenedor").offsetTop);
    }
    cargandoRef.current.textContent = " ";
    cargandoRef.current.classList.remove(`loader`);
  };

  const recaptchaVerificador1 = (response) => {
    //console.log("resultado: ",response)
    if (response) {
      setIsVerificado1(true);
    }
    cargandoRef.current.textContent = " ";
    cargandoRef.current.classList.remove(`loader`);
    //console.log("verificado!", this.state.isVerificado)
  };
  const recaptchaCargador1 = () => {
    //console.log("se ha cargado adecuadamente el recaptcha para ver un usuario")
  };

  const limpiarState = () => {
    setRifaDatos(props.datosBoletas);
    setSpinner("");
    setHabilitarIngresoDatos(false);
    setIsVerificado1(false);
    setEleccionRandom("");
    setToogleEnable(false);
  };

  const handleSubmit = (e) => {
    const valor = document.getElementById("boletasNum").value;
    //console.log("eleccion: "+eleccionRandom+" "+valor)
    if (eleccionRandom != valor) {
      alert(
        `La boleta número ${eleccionRandom} ha sido adquirida hace un instante, elige otro número`
      );
      limpiarState();
    } else {
      props.onAddTodo(
        nombreParti,
        correoParti,
        confirmCelularParti,
        celularParti,
        eleccionRandom,
        codigo
      );
      //limpiarState()
    }
    cargandoRef.current.textContent = " ";
    cargandoRef.current.classList.remove(`loader`);
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    //console.log(e);
    //console.log(value, name);
    switch (name) {
      case "spinner":
        if (value != "") {
          setHabilitarIngresoDatos(true);
          setEleccionRandom(value);
          window.scrollTo(0, document.getElementById("contenedor").offsetTop);
        } else {
          //console.log("El código no puede estar vacio");
        }
        //console.log(Object.keys(this.state.rifaDatos));
        //console.log(Object.entries(this.state.rifaDatos));
        //console.log(Object.values(this.state.rifaDatos));
        //Object.values(this.state.rifaDatos)[Object.keys(this.state.rifaDatos).findIndex("")]
        break;
      case "nombre":
        nombreParti = value;
        break;

      case "correo":
        correoParti = value;
        break;

      case "celular":
        celularParti = value;
        break;

      case "telefonoConfirm":
        confirmCelularParti = value;
        break;
      default:
        //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }
    cargandoRef.current.textContent = " ";
    cargandoRef.current.classList.remove(`loader`);
  };

  const serachIndex = (contador) => {
    ////console.log(Object.keys(this.state.rifaDatos).length)
    //console.log("Sale algo 1---",contador)
    //console.log("comparador",Object.keys(this.state.rifaDatos).indexOf(contador+"a"))
    var index = Object.keys(rifaDatos).indexOf(contador + "a");
    //console.log("index",Object.values(this.state.rifaDatos)[index])
    //console.log("index: "+index)
    return Object.values(rifaDatos)[index];
  };

  return (
    <div className="card">
      <h2 className="lead-6">Escoge una opción:</h2>
      <div className="form-group">
        {rifaDatos && (
          <Row>
            <Col sm={4}>
              <p className="lead-6">
                <button
                  className="boton-random"
                  onClick={() => {
                    cargandoRef.current.textContent = " ";
                    cargandoRef.current.classList.add(`loader`);
                    setTimeout(() => {
                      handleRandomNum();
                    }, 1);
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/randomCard.png"}
                    height="50"
                  />
                  <p
                    className={
                      habilitarIngresoDatos
                        ? "lead-aux-1 text"
                        : "lead-aux-1 parpadea text"
                    }
                  >
                    ¡Déjalo a la suerte!
                  </p>
                </button>
              </p>
            </Col>
            <Col sm={8}>
              <select
                id="boletasNum"
                name="spinner"
                className={
                  habilitarIngresoDatos
                    ? "modo-select text"
                    : "modo-select parpadea text"
                }
                onChange={(evento, aux) => {
                  const nombre = evento.target.name;
                  const valor = evento.target.value;
                  const conjunto = { target: { name: nombre, value: valor } };
                  cargandoRef.current.textContent = " ";
                  cargandoRef.current.classList.add(`loader`);
                  setTimeout(() => {
                    handleInputChange(conjunto);
                  }, 1);
                }}
                defaultValue=""
                value={eleccionRandom}
              >
                <option disabled={true} value="">
                  ¡Escoge un número!
                </option>
                {estadoRifa == 1 &&
                  Object.keys(rifaDatos).length == 43 &&
                  Object.values(rifaDatos).map((x, i) => {
                    if (
                      Object.values(serachIndex(i + 1))[
                        Object.keys(serachIndex(i + 1)).findIndex(
                          (el) => el === "estado"
                        )
                      ] != "-" &&
                      Object.values(serachIndex(i + 1))[
                        Object.keys(serachIndex(i + 1)).findIndex(
                          (el) => el === "estado"
                        )
                      ] != "--"
                    ) {
                      return (
                        <option
                          key={i}
                          value={
                            Object.values(serachIndex(i + 1))[
                              Object.keys(serachIndex(i + 1)).findIndex(
                                (el) => el === "estado"
                              )
                            ]
                          }
                          label={i + 1}
                        >
                          {i + 1}
                        </option>
                      );
                    }
                  })}
                {estadoRifa == 1 &&
                  Object.keys(rifaDatos).length != 43 &&
                  Object.values(rifaDatos).map((x, i) => {
                    if (
                      Object.values(serachIndex(i))[
                        Object.keys(serachIndex(i)).findIndex(
                          (el) => el === "estado"
                        )
                      ] != "-" &&
                      Object.values(serachIndex(i))[
                        Object.keys(serachIndex(i)).findIndex(
                          (el) => el === "estado"
                        )
                      ] != "--"
                    ) {
                      return (
                        <option
                          key={i}
                          value={
                            Object.values(serachIndex(i))[
                              Object.keys(serachIndex(i)).findIndex(
                                (el) => el === "estado"
                              )
                            ]
                          }
                        >
                          {props.boletasNum === 300 ||
                          props.boletasNum === 500 ||
                          props.boletasNum === 1000
                            ? i < 100
                              ? i < 10
                                ? "00" + i.toString()
                                : "0" + i.toString()
                              : i
                            : i < 10
                            ? "0" + i.toString()
                            : i}
                        </option>
                      );
                    }
                  })}

                {estadoRifa == 2 && (
                  <option disabled={true} value="">
                    Este talonario ya ha caducado...
                  </option>
                )}
                {estadoRifa == 3 && (
                  <option disabled={true} value="">
                    Este talonario las ha cancelado el anfitrión responsable...
                  </option>
                )}
              </select>
            </Col>
          </Row>
        )}
        <div ref={cargandoRef}> </div>
      </div>

      <form
        onSubmit={(event) => {
          cargandoRef.current.textContent = " ";
          cargandoRef.current.classList.add(`loader`);
          event.preventDefault();
          setTimeout(() => {
            handleSubmit(event);
          }, 1);
        }}
        className="card-body"
      >
        {habilitarIngresoDatos && <h2 className="lead-6">Ingresa tus datos</h2>}
        {habilitarIngresoDatos && (
          <p className="lead-6">
            Escogiste la boleta:{" "}
            {props.boletasNum === 300 ||
            props.boletasNum === 500 ||
            props.boletasNum === 1000
              ? eleccionRandom < 100
                ? eleccionRandom < 10
                  ? "00" + eleccionRandom.toString()
                  : "0" + eleccionRandom.toString()
                : eleccionRandom
              : eleccionRandom < 10
              ? "0" + eleccionRandom.toString()
              : eleccionRandom}
          </p>
        )}
        {habilitarIngresoDatos && (
          <div className="form-group">
            <input
              type="text"
              name="nombre"
              className="form-control"
              defaultValue=""
              onChange={handleInputChange}
              placeholder="Nombre completo"
            />
          </div>
        )}
        {habilitarIngresoDatos && (
          <div className="form-group">
            <textarea
              type="text"
              name="correo"
              className="form-control"
              defaultValue=""
              onChange={handleInputChange}
              maxlength="70"
              placeholder="Observaciones (Opcional, solo visible para el anfitrión del talonario)"
            />
          </div>
        )}

        {habilitarIngresoDatos && (
          <div>
            <p className="lead-6">
              <b>
                Ingresa tu número de teléfono{" "}
                <u id="tooltipAclara">(Obligatorio)</u>
              </b>
            </p>
            <Tooltip
              placement="center"
              target="tooltipAclara"
              isOpen={toogleEnable}
              toggle={toggleTooltip}
              delay={{ show: 0, hide: 3000 }}
            >
              Recuerda que con este número podrás ver el comprobante de tu
              boleta, ingresando con el botón 'Ver Boletas'
            </Tooltip>
          </div>
        )}

        {habilitarIngresoDatos && (
          <div className="form-group">
            <input
              type="tel"
              name="celular"
              pattern="[0-9]*"
              className="form-control"
              defaultValue=""
              onChange={handleInputChange}
              placeholder="Número de teléfono"
            />
            <input
              type="tel"
              name="telefonoConfirm"
              pattern="[0-9]*"
              className="form-control"
              defaultValue=""
              onChange={handleInputChange}
              placeholder="Confirmar número de teléfono"
            />
          </div>
        )}
        {!isVerificado1 && habilitarIngresoDatos && (
          <div>
            <p className="lead-6">
              <b>Para continuar verifica la casilla: </b>
            </p>
            <Recaptcha
              sitekey="6Lci89YZAAAAAPiQn7YFoie-yUx6339BueUScWbL"
              render="explicit"
              hl="es"
              onloadCallback={recaptchaCargador1}
              verifyCallback={(respuesta) => {
                cargandoRef.current.textContent = " ";
                cargandoRef.current.classList.add(`loader`);
                setTimeout(() => {
                  recaptchaVerificador1(respuesta);
                }, 1);
              }}
            />
          </div>
        )}
        {isVerificado1 && habilitarIngresoDatos && (
          <button
            type="submit"
            className="boton-primario parpadeo"
            onClick={() => {
              cargandoRef.current.textContent = " ";
              cargandoRef.current.classList.add(`loader`);
            }}
          >
            Presionar para adquirir
          </button>
        )}
      </form>
    </div>
  );
}
