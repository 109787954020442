import React from 'react';
import ReactSelect from 'react';
import {Container, Row, Col} from 'reactstrap';
import '../App.css';


export default function Terminos(props){
    return (
      <div className='scroll-terminos' style={{ overflowY: 'scroll'}}>
        <Container>
          <Col>
          <br/>
          <strong dir="ltr">
              TÉRMINOS Y CONDICIONES
          </strong>
          <p dir="ltr">
              Última actualización: 29 de noviembre de 2021
          </p>
          <p dir="ltr">
              <br/>
              Estos Términos y Condiciones regulan la descarga, acceso y utilización de
              la aplicación móvil y web “RifaYa - Talonario virtual de rifas”, que ponen
              a disposición de los usuarios.
              <br/>
              RifaYa está disponible de forma gratuita y puede ser descargado en la
              tienda virtual Play Store de Google en cualquier dispositivo que funcione
              mediante un sistema operativo Android, y la aplicación web disponible en
              www.rifaya-app.online.
              <br/>
              El acceso a RifaYa supone que el usuario reconoce haber aceptado y
              consentido sin reservas de las presentes condiciones de uso.
              <br/>
              RifaYa se define como un software as a service (SaaS) creado para facilitar
              la gestión de boletas de talonarios. Funciona como un talonario electrónico
              donde los usuarios pueden hacer su registro, crear su talonario y
              determinar sus condiciones y especificaciones, administrar sus boletas,
              invitar a los interesados a adquirir las boletas mediante un link, además
              de funciones de personalización especiales. Por lo cual RifaYa y sus
              creadores estipulan los siguientes términos y condiciones:
              <br/>
          </p>
          <ol>
              <li dir="ltr">
                  <p dir="ltr">
                      RifaYa y sus creadores (Bifrost Solutions S.A.S) no se hacen
                      responsables por la realización, desarrollo o cumplimiento de
                      ningún sorteo, rifa o concurso realizado mediante la aplicación, la
                      responsabilidad del desarrollo de un sorteo, rifa o concurso recae
                      únicamente en la persona que decide hacer su registro, crear el
                      talonario e invitar a las personas a adquirir las boletas.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      RifaYa al ser una aplicación que permite la administración y el
                      manejo de sorteos, rifas y/o concursos creadas por personas
                      naturales y/o jurídicas de forma digital, no se hace responsable de
                      ninguna transacción monetaria o bancaria que implique el pago de
                      las boletas al creador del talonario, es responsabilidad del
                      creador del talonario hacer el cobro de las boletas a las personas
                      que desean adquirir las boletas, por lo cual RifaYa no actúa de
                      ninguna forma como un intermediario en la transacción de dinero.
                      Además es responsabilidad del creador del talonario, el tratamiento
                      de los datos registrados de los participantes o usuarios de su(s)
                      talonario(s).
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      RifaYa brindará soporte a los creadores de los talonarios en cuanto
                      a la utilización de la aplicación, inconvenientes técnicos en el
                      funcionamiento de la aplicación o dudas con respecto a las
                      características y opciones que brinda RifaYa, al correo
                      soporte@rifaya-app.online.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Las personas que deciden adquirir las boletas mediante la página
          web            <a href="https://www.rifaya-app.online/">www.rifaya-app.online</a>
                      deben tener en cuenta que la RifaYa y sus creadores no se hacen
                      responsables por ningún tipo de pago o transacción. En el sitio web
                      se permitirá a las personas que quieran hacerse con las boletas de
                      un determinado talonario, hacer su registro, seleccionar las
                      boletas y hacer modificaciones de las mismas. Sin embargo esto no
                      asegura que el número seleccionado ya sea de la persona pues esta
                      debe ser paga al creador del talonario y el debe hacer la marcación
                      que indique la boleta ya ha sido enteramente paga.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      RifaYa y sus fundadores no se hacen responsables por
                      incumplimientos en la entrega de premios, es única y exclusivamente
                      la responsabilidad del creador del talonario.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Las personas que hagan uso de RifaYa - Talonario virtual de rifas
                      para crear sorteos, concursos o rifas son totalmente conscientes de
                      toda la regulación, legislación y/o normatividad existente para
                      controlar los sorteos, concursos o rifas en el país en que son
                      jugadas, por lo cual al usar la aplicación RifaYa declaran estar
                      cumpliendo a cabalidad todas las normas de su país con referencia a
                      este tipo de actividad. RifaYa y sus desarrolladores no se hacen
                      responsables si un usuario infringe la regulación, legislación y/o
                      normatividad de su país.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Los servicios y el contenido digital ofrecidos por RifaYa -
                      Talonario virtual de rifas, se ofrecen mediante la App móvil,
                      usando como medio de pago la pasarela de pagos de Play Store de
                      Google, por lo cual los datos bancarios de los ususarios se
                      encuentran protegidos y regulados directamente por los términos y
                      condiciones expuestos por Google en:
                      <a href="https://play.google.com/intl/es-419_pe/about/play-terms/">
                          https://play.google.com/intl/es-419_pe/about/play-terms/
                      </a>
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      La política de reembolos por compras realizadas en la App, se rigen
                      directamente por los términos y condiciones expuestos por Google
                      Play en:
                      <br/>
                      <a
                          href="https://support.google.com/googleplay/answer/2479637?p=details_refundpolicy&amp;ctx=com.bifrost.rifaya&amp;visit_id=637737558047318761-4253565873&amp;rd=1"
                      >
                          https://support.google.com/googleplay/answer/2479637?p=details_refundpolicy&amp;ctx=com.bifrost.rifaya&amp;visit_id=637737558047318761-4253565873&amp;rd=1
                      </a>
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Falla en el servicio: Si el usuario acepta obtener alguno de los
                      servicios y durante el periodo de disfrute del servicio, el
                      funcionamiento normal de la app o del servicio adquirido se ve
                      afectado, el usuario recibirá una recompensa en puntos de la App
                      los cuales podrá utilizar dentro de la misma aplicación para
                      canjear por funciones especiales u otros servicios inmersos en la
                      App.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      RifaYa y sus desarrolladores (Bifrost Solutions S.A.S) podrán
                      modificar autónomamente y en cualquier momento en aspectos
                      formales, procedimentales o sustanciales los presentes Términos y
                      Condiciones de uso de RifaYa para sus versiones web y móvil, los
                      cuales serán actualizados y puestos a disposición tanto de los
                      creadores de las rifas, como de los participantes e interesados,
                      siendo la última versión publicada la que regulará el uso de
                      RifaYa. Así mismo RifaYa y sus desarrolladores, cuentan con plena
                      autonomía para modificar los usos de RifaYa en su versión web y
                      móvil permitidos a los creadores, interesados y participantes, con
                      el único deber de informar por un medio virtual que permita su
                      publicación y comunicación al público.
                  </p>
              </li>
          </ol>
          <p dir="ltr">
              Teniendo en cuenta lo anterior, cualquier usuario que quiera hacer uso de
              la aplicación RIfaYa ya sea como creador de talonarios o como persona que
              adquiere las boletas, acepta los anteriores términos y condiciones.
          </p>
          <div>
              <br/>
          </div>
          </Col>
         <br/>
          <Col>
          <strong dir="ltr">
              POLÍTICAS DE PRIVACIDAD PARA LA APLICACIÓN “RIFAYA”
          </strong>
          <p dir="ltr">
              <strong id="docs-internal-guid-8f2c0b6f-7fff-7840-5e23-de32fadc4493">
                  <br/>
              </strong>
          </p>
          <p dir="ltr">
              Última actualización: 29 de noviembre de 2021
          </p>
          <p dir="ltr">
              Los datos personales registrados en RifaYa en su versión móvil y web son
              manejados conforme a la ley 1581 de 2012 de Colombia. Por lo cual RifaYa,
              propiedad de Bifrost Solutions S.A.S con NIT 901522337-5 se compromete a
              conservar la información bajo las condiciones de seguridad necesarias para
              impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o
              fraudulento. Por lo cual usted como usuario acepta los siguientes términos
              relacionados al uso de sus datos personales:
          </p>
          <p dir="ltr">
              <strong>
                  <br/>
              </strong>
          </p>
          <ol>
              <li dir="ltr">
                  <p dir="ltr">
                      Autoriza que sus datos personales serán registrados en nuestra base
                      de datos y manejados bajo confidencialidad, no serán vendidos,
                      adulterados, compartidos y/o publicados por ningún medio físico o
                      electrónico.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Autoriza que en el enlace web que se crea del talonario al crear un
                      nuevo talonario desde aplicación móvil, es el único lugar donde se
                      le dispondrá a los participantes o usuarios del talonario en su
                      versión web, los datos de contacto registrados del creador del
                      respectivo talonario.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Autoriza a que los datos sean tratados de forma anónima para
                      realizar estudios de mercado, comportamiento de masas o una
                      población en específico, entre otros estudios de interés de RifaYa
                      y sus desarrolladores.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Autoriza el tratamiento de sus datos personales teniendo en cuenta
                      que los mismos serán usados únicamente por el creador de la
                      talonario y por RifaYa y sus creadores para hacer el pertinente
                      análisis de los datos, con el fin de mejorar la experiencia de los
          usuarios con la aplicación móvil RifaYA y el sitio web            <a href="http://www.rifaya-app.online">www.rifaya-app.online</a>,
                      además RifaYa y sus creadores usan sus datos para promocionar el
                      uso de nuestros servicios y compartir contenido promocional e
                      informativo con usted.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Autoriza que con el fin de disponer con mayor efectividad los datos
                      de los usuarios de la App, se realizará una depuración de los datos
                      de los talonarios que tengan un tiempo mayor a 6 meses desde su
                      creación. Por lo cuál los usuarios no podrán disponer de la
                      información una vez se cumpla el plazo de tiempo mencionado.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      En caso de que usted como usuario de RifaYa, ya sea de su versión
                      móvil o de su versión web desee que sus datos personales sean
                      suprimidos de nuestra base de datos debe enviar un correo
                      electrónico a bifrost.aplicaciones@gmail.com con el asunto “Datos
                      personales” y con su solicitud en el cuerpo del correo.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      Bifrost Solutions S.A.S. como creadores y propietarios de RifaYa -
                      Talonario virtual de rifas, se reconocen como los responsables del
                      tratamiento de datos personales de los creadores de los talonarios.
                      Y pueden ser contactados a los siguientes números celulares:
                      +573028468860/+573505945976, o al correo electrónico
                      <a href="mailto:bifrost.aplicaciones@gmail.com">
                          bifrost.aplicaciones@gmail.com
                      </a>
                      . Los creadores de los talonarios se reconocen como los
                      responsables del tratamiento de los datos registrados de los
                      participantes de sus talonarios.
                  </p>
              </li>
              <li dir="ltr">
                  <p dir="ltr">
                      En caso de que el usuario como titular información registrada desee
                      conocer y consultar sus datos personales registrados en nuestra
                      base de datos, debe hacer una solicitud por escrita y enviarla al
                      correo electrónico bifrost.aplicaciones@gmail.com. Una vez recibida
                      la solicitud RifaYa cuenta con 10 días hábiles a partir del
                      recibido de la solicitud para formular la consulta. En caso de
                      poder atender la consulta en los primeros 10 días RifaYa y sus
                      creadores se comprometen a informar al titular solicitante la razón
                      del retraso y estipulando una fecha para el cumplimiento de
                      solicitud la cual no puede de ninguna forma ser extendida más de 5
                      días después de la fecha determinada.
                  </p>
              </li>
          </ol>
          <p dir="ltr">
              Teniendo en cuenta lo anterior, cualquier usuario que quiera hacer uso de
              la aplicación RIfaYa ya sea como creador de talonarios o como persona que
              adquiere las boletas, acepta la política de privacidad anteriormente
              estipulada.
          </p>
          </Col>
        </Container>
       
        
      </div>
    )
}
